import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import styles from './StudyCircleStyle.module.css';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Box, Icon } from '@material-ui/core';
import { ModalComponent, Dialogbox } from '../../../../components/index';
import { saveAs } from 'file-saver';
// import * as ReactBootStrap from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
// import { getGroupInstitutionBoards } from "../../../../api/groupInsitutionBoard";
import { getAllSubjectsByBoardId } from '../../../../api/InstitutionSubjects';
import {
  getGroupStudyCircles,
  postGroupStudyCircles,
  getGroupStudyCircleList,
  getGroupStudyCircleDetails,
  getGroupStudyCircleListForExcelExport,
  postGroupStudyCircleStudyCircles,
  getGroupStudyCircleStudyCircles,
  postGroupStudyCircleSubject,
  getGroupStudyCircleSubjects,
  deleteGroupStudyCircleSubject,
  uploadGroupStudyCircleSubjectGroupEducator,
  deleteGroupStudyCircleSubjectGroupEducator,
  getGroupStudyCircleSubjectGroupEducators,
} from '../../../../api/groupStudyCircle';
import { createStudyCircle, editStudyCircleDetails } from '../../../../api/studyCircle';

const initialBox = {
  study_circleName: '',
  incharge_studyCircle: '',
  // desc:""
};

const validationBox = Yup.object({
  study_circleName: Yup.string(),
  incharge_studyCircle: Yup.string(),
  // desc:Yup.string()
});

const initialFormBox = {
  board: '',
  course: '',
  subject: '',
  sub_desc: '',
};

const validationFormBox = Yup.object({
  boarde: Yup.string(),
  course: Yup.string(),
  subject: Yup.string(),
  sub_desc: Yup.string(),
});

var study_circle_ID;
// var cmn_study_circle_teacher_subject_ID;
var callback_value;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var downloadApiUrl;
var uploadApiUrl;
var groupInstId;
var shName;
var prHierarchy;

const StudyCircle = () => {
  const [errorUpload, setErrorUpload] = useState(false);
  // const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log(isLoading, setIsLoading, downloadApiUrl);
  const [showEdu, setShowEdu] = useState(false);
  const [state, setState] = useState({
    // educator_head: [
    //   "Educator",
    //   "Board",
    //   "Grade",
    //   "Subject",
    //   "More details",
    //   "",
    // ],
    subject_head: ['Board', 'Course', 'Subject', 'Group Educators', ''],
    study_circle_t_body: [],
    educator_body: [],
    subStudyCircle_body: [],
    subject_body: [],
    drop_board: [],
    drop_course: [],
    drop_subject: [],
    drop_edu: [],
    incharge_data: [],
    selected_class: [],
    board: '',
    classchange: '',
    subjectchange: '',
    study_circle_Selected_Name: '',
    study_circle_Index: '',
    incharge_grp_study_circle: '',
    desc: '',
    sub_desc: '',
    subId: '',
  });

  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('groupTabIndex', 3);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  //   console.log(process.env.REACT_APP_ACCESS_KEY_ID);
  // console.log(process.env.REACT_APP_END_POINT);
  // console.log(process.env.REACT_APP_SECRET_KEY);

  const fetchResult = async () => {
    try {
      // PORT-GSC-3: Display Group Study Circle List

      const getTableData = await getGroupStudyCircleList();
      // await Axios.get(
      //   `${BASE_URL}/group_study_circles/search?search_text=`,
      //   Auth
      // );

      setLoading(false);
      console.log(getTableData.data.data);
      setState({
        ...state,
        study_circle_t_body: getTableData.data.data,
      });
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTableClick = async (index, grpInstId, study_circle_Selected_Name, desc, shortName, parentHierarchy) => {
    console.log(shortName, parentHierarchy);
    setLoading(true);
    shName = shortName;
    prHierarchy = parentHierarchy;
    study_circle_ID = index;
    groupInstId = grpInstId;
    window.localStorage.setItem('table_index', index);
    window.localStorage.setItem('grpInst_id', grpInstId);
    window.localStorage.setItem('table_study_circle_Selected_Name', study_circle_Selected_Name);
    // PORT-IB-5: Retrieve list of boards of an institution
    try {
      const getBoard = await getBoardsByNameInInstitution(school_id, Auth);
      // await Axios.get(
      //   // `${BASE_URL}/group_institutions/${grpInstId}/boards?parent_hierarchy=${parentHierarchy}&short_name=${shortName}`,
      //   `${BASE_URL}/institutions/${school_id}/boards/`,
      //   Auth
      // );

      // PORT-GSC-4: Display Basic Details of Group Study Circle

      const grpstdyCircleInfo = await getGroupStudyCircleDetails(study_circle_ID);
      // await Axios.get(
      //   `${BASE_URL}/group_study_circles/${study_circle_ID}/basic_info`,
      //   Auth
      // );

      // PORT-GSC-7: Display the Study Circles under a Group Study Circle

      const subStudyCircleList = await getGroupStudyCircleStudyCircles(study_circle_ID);
      // await Axios.get(
      //   `${BASE_URL}/group_study_circles/${study_circle_ID}/study_circles/`,
      //   Auth
      // );

      // PORT-GSC-9: Display Subjects for a Group Study Circle

      const subjectList = await getGroupStudyCircleSubjects(study_circle_ID, grpInstId, parentHierarchy, shortName);
      // await Axios.get(
      //   `${BASE_URL}/group_study_circles/${study_circle_ID}/subjects?group_institution_id=${grpInstId}&parent_hierarchy_path=${parentHierarchy}&short_name=${shortName}`,
      //   Auth
      // );
      console.log(subjectList.data.data);
      console.log(shName, prHierarchy);

      setState({
        ...state,
        // drop_edu: grpEducatorList.data,
        subject_body: subjectList.data.data,
        subStudyCircle_body: subStudyCircleList.data.data,
        study_circle_Selected_Name: study_circle_Selected_Name,
        incharge_grp_study_circle: grpstdyCircleInfo?.data?.data[0]?.incharge_group_educator_name,
        desc: desc,
        showHidePlus: false,
        drop_board: getBoard.data.data,
      });

      window.localStorage.setItem('page_Index', 'sub_Index');
      setLoading(false);
      setNextPage(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubjectDelete = async (e, id) => {
    window.localStorage.setItem('page_Index', 'sub_Index');
    console.log(groupInstId, study_circle_ID, id);
    try {
      // PORT-GSC-10: Delete Subject from  group study circle

      await deleteGroupStudyCircleSubject(study_circle_ID, groupInstId, id);
      toast.success('data deleted successfully');
      console.log(shName, prHierarchy);
      onTableClick(
        window.localStorage.getItem('table_index'),
        window.localStorage.getItem('grpInst_id'),
        window.localStorage.getItem('table_study_circle_Selected_Name'),
        state.desc,
        shName,
        prHierarchy,
      );
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
    // await Axios.delete(
    //   `${BASE_URL}/group_study_circles/${study_circle_ID}/group_institutions/${groupInstId}/subjects/${id}`,
    //   Auth
    // )
    //   .then((response) => {
    //     toast.success("data deleted successfully");
    //     console.log(shName, prHierarchy);
    //     onTableClick(
    //       window.localStorage.getItem("table_index"),
    //       window.localStorage.getItem("grpInst_id"),
    //       window.localStorage.getItem("table_study_circle_Selected_Name"),
    //       state.desc,
    //       shName,
    //       prHierarchy
    //     );
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     // setTimeout(() => {
    //     //   window.location.reload(false);
    //     // }, 3000);
    //   });
  };

  const handleClosePlus = (value) => {
    setState({ ...state, showHidePlus: false });
  };

  const handleClickOpenAdd = () => {
    setState({ ...state, showHidePlus: true });
  };

  // const handleClickOpenPlus = () => {
  //   setState({ ...state, showHidePlus: true });
  // };
  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = () => {
    setState({ ...state, showHide: true });
  };

  const callBack = () => {
    callback_value ? window.location.reload(false) : (callback_value = null);
    setNextPage(false);
    setState({ ...state, showHide: false });
  };

  const changedBoard = async (e, selectedvalue) => {
    if (selectedvalue === 'board') {
      board_id = e.target.value;
    } else if (selectedvalue === 'course') {
      course_id = e.target.value;
    } else if (selectedvalue === 'subject') {
      subject_id = e.target.value;
    }
    console.log(shName, groupInstId, board_id, course_id, prHierarchy);
    try {
      const getCourse = await getCoursesListOfAnInstitution(0, board_id, Auth);
      // await Axios.get(
      //   // `${BASE_URL}/group_institutions/${groupInstId}/boards/${board_id}/courses?parent_hierarchy=${prHierarchy}&short_name=${shName}`,
      //   `${BASE_URL}/institutions/0/boards/${board_id}/courses`,
      //   Auth
      // );

      const getSubject = await getAllSubjectsByBoardId(0, board_id, course_id, '');
      // await Axios.get(
      //   // `${BASE_URL}/group_institutions/${groupInstId}/boards/${board_id}/courses/${course_id}/subjects?parent_hierarchy=${prHierarchy}&short_name=${shName}`,
      //   `${BASE_URL}/institutions/0/boards/${board_id}/courses/${course_id}/subjects`,
      //   Auth
      // );
      console.log(getCourse.data.data);
      console.log(getSubject.data.data);
      setState((prevState) => ({
        ...prevState,
        drop_course: getCourse.data.data,
        drop_subject: getSubject.data.data,
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const downloadFile = async (e, val) => {
    const grpStdid = window.localStorage.getItem('table_index');
    console.log(prHierarchy, shName);
    let response;
    try {
      if (val === 'downloadGsc') {
        // downloadApiUrl = `${BASE_URL}/group_study_circles/excel-export`;
        // PORT-GSC-1: Excel export of Group Study Circles

        response = await getGroupStudyCircles();
      } else if (val === 'downloadStd') {
        // PORT-GSC-5: Excel Export of Study Circle List for a Given Group Study Circle

        response = await getGroupStudyCircleListForExcelExport(grpStdid, prHierarchy, shName);
        // downloadApiUrl = `${BASE_URL}/group_study_circles/${grpStdid}/study_circles/excel-export?parent_hierarchy_path=${prHierarchy}&short_name=${shName}`;
      }
      let filename = response?.headers['content-disposition']?.split('=')[1];
      var blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, filename);
      toast.success('file downloaded');
      // await Axios.get(downloadApiUrl, {
      //   ...Auth,
      //   responseType: "arraybuffer",
      // }).then((response) => {
      //   // console.log(response)
      //   let filename = response.headers["content-disposition"].split("=")[1];
      //   var blob = new Blob([response.data], {
      //     type: "application/octet-stream",
      //   });
      //   saveAs(blob, filename);
      //   toast.success("file downloaded");
      // });
    } catch (err) {
      console.log(err, 'error');
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      toast.error('No records');
    }
  };
  const uploadFile = async (e, val) => {
    const grpStdid = window.localStorage.getItem('table_index');
    console.log(uploadApiUrl);
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);
    formdata.append('filetoupload', file);
    try {
      if (val === 'uploadGsc') {
        // PORT-GSC-2: Excel Import of Group Study Circles

        await postGroupStudyCircles(formdata);
        // uploadApiUrl = `${BASE_URL}/group_study_circles/excel-import`;
      } else if (val === 'uploadStd') {
        // PORT-GSC-6: Excel Import of Study Circles into Group Study Circle

        await postGroupStudyCircleStudyCircles(grpStdid, formdata);
        // uploadApiUrl = `${BASE_URL}/group_study_circles/${grpStdid}/study_circles/excel-import`;
      }
      toast.success('File Uploaded Successfully');
      if (val === 'uploadGsc') {
        await fetchResult();
      } else if (val === 'uploadStd') {
        console.log(123876);
        try {
          // PORT-GSC-7: Display the Study Circles under a Group Study Circle
          console.log('error/group_study_circles/study_circle_ID/study_circles/');
          const subStudyCircleList = await getGroupStudyCircleStudyCircles(study_circle_ID);
          // await Axios.get(
          //   `${BASE_URL}/group_study_circles/${study_circle_ID}/study_circles/`,
          //   Auth
          // );
          console.log(subStudyCircleList.data.data, 'error/group_study_circles/study_circle_ID/study_circles/');
          setState({
            ...state,
            subStudyCircle_body: subStudyCircleList.data.data,
          });
        } catch (error) {
          console.log(error, 'error/group_study_circles/study_circle_ID/study_circles/');
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            toast.error(`oops! error while upload file  check in the modal..`);
          }
          setLoading(false);
        }
      }
      setLoading(false);
      console.log(shName, prHierarchy);
    } catch (err) {
      if (err) {
        setErrorUpload(true);
        setLoading(false);
        console.log(err);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
          toast.error(`oops! error while upload file  check in the modal..`);
        }
        // setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      }
    }
    // Axios({
    //   url: uploadApiUrl,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then(async (res) => {
    //     toast.success("File Uploaded Successfully");
    //     if (val === "uploadGsc") {
    //       await fetchResult();
    //     } else if (val === "uploadStd") {
    //       console.log(123876);
    //       try {
    //         const subStudyCircleList = await Axios.get(
    //           `${BASE_URL}/group_study_circles/${study_circle_ID}/study_circles/`,
    //           Auth
    //         );
    //         console.log(subStudyCircleList.data.data);
    //         setState({
    //           ...state,
    //           subStudyCircle_body: subStudyCircleList.data.data,
    //         });
    //       } catch (error) {
    //         if (error.response && error.response.data) {
    //           console.log(error.response.data.message);
    //           toast.error(error.response.data.message);
    //         } else {
    //           toast.error(
    //             `oops! error while upload file  check in the modal..`
    //           );
    //         }
    //         setLoading(false);
    //       }
    //     }
    //     setLoading(false);
    //     console.log(shName, prHierarchy);
    //     // onTableClick(
    //     //   window.localStorage.getItem("table_index"),
    //     //   window.localStorage.getItem("grpInst_id"),
    //     //   window.localStorage.getItem("table_study_circle_Selected_Name"),
    //     //   state.desc,
    //     //   shName,
    //     //   prHierarchy
    //     // );
    //   })
    //   .catch((err) => {
    //     if (err) {
    //       setErrorUpload(true);
    //       setLoading(false);
    //       console.log(err);
    //       if (err.response && err.response.data) {
    //         console.log(err.response.data.message);
    //         toast.error(err.response.data.message);
    //         toast.error(`oops! error while upload file  check in the modal..`);
    //       }
    //       // setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
  };

  // console.log(study_circle_ID)

  const handleClickIcon = async (e, val) => {
    subject_id = val;
    try {
      // PORT-GSC-13: Retrieve list of Group educators available for a Group study circle subject

      const grpEducatorList = await getGroupStudyCircleSubjectGroupEducators(study_circle_ID, groupInstId, subject_id);
      // await Axios.get(
      //   `${BASE_URL}/group_study_circles/${study_circle_ID}/group_institutions/${groupInstId}/subjects/${subject_id}/educators`,
      //   Auth
      // );
      console.log(grpEducatorList.data.data);
      setState((prevState) => ({
        ...prevState,
        subId: val,
        drop_edu: grpEducatorList.data.data,
      }));
      setShowEdu(true);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const addEducatorToSubject = async (e) => {
    console.log(e.target.value);
    let values = e.target.value.split(' ');
    const payload = {
      group_institution_id: groupInstId,
      group_educator_id: values[0],
      description: values[1],
    };
    try {
      // PORT-GSC-11: Add Group Educator to Group Study Circle Subject

      const res = await uploadGroupStudyCircleSubjectGroupEducator(study_circle_ID, subject_id, payload);
      toast.success('Educator added successfully');
      if (res.data.status === true) {
        // setOpen(false);
        console.log(shName, prHierarchy);
        onTableClick(
          window.localStorage.getItem('table_index'),
          window.localStorage.getItem('grpInst_id'),
          window.localStorage.getItem('table_study_circle_Selected_Name'),
          state.desc,
          shName,
          prHierarchy,
        );
        setState({ ...state, showHidePlus: false });
      }
      setShowEdu(false);
      window.localStorage.setItem('page', 'nextScreen');
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }

    // Axios.post(
    //   `${BASE_URL}/group_study_circles/${study_circle_ID}/subjects/${subject_id}/group_educators`,
    //   {
    //     group_institution_id: groupInstId,
    //     group_educator_id: values[0],
    //     description: values[1],
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("Educator added successfully");
    //     if (res.data.status === true) {
    //       // setOpen(false);
    //       console.log(shName, prHierarchy);
    //       onTableClick(
    //         window.localStorage.getItem("table_index"),
    //         window.localStorage.getItem("grpInst_id"),
    //         window.localStorage.getItem("table_study_circle_Selected_Name"),
    //         state.desc,
    //         shName,
    //         prHierarchy
    //       );
    //       setState({ ...state, showHidePlus: false });
    //     }
    //     setShowEdu(false);
    //     window.localStorage.setItem("page", "nextScreen");
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };

  const handleEducatorDelete = async (e, subId, subEduId) => {
    // console.log(subjectId,subEduId)
    window.localStorage.setItem('page_Index', 'sub_Index');
    try {
      // PORT-GSC-12: Delete Group Educator from Group Study Circle Subject

      await deleteGroupStudyCircleSubjectGroupEducator(study_circle_ID, subject_id, subId, subEduId);
      toast.success('data deleted successfully');
      console.log(shName, prHierarchy);
      onTableClick(
        window.localStorage.getItem('table_index'),
        window.localStorage.getItem('grpInst_id'),
        window.localStorage.getItem('table_study_circle_Selected_Name'),
        state.desc,
        shName,
        prHierarchy,
      );
      setShowEdu(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }

    // await Axios.delete(
    //   `${BASE_URL}/group_study_circles/${study_circle_ID}/group_institutions/${groupInstId}/subjects/${subId}/educators/${subEduId}`,
    //   Auth
    // )
    //   .then((response) => {
    //     toast.success("data deleted successfully");
    //     console.log(shName, prHierarchy);
    //     onTableClick(
    //       window.localStorage.getItem("table_index"),
    //       window.localStorage.getItem("grpInst_id"),
    //       window.localStorage.getItem("table_study_circle_Selected_Name"),
    //       state.desc,
    //       shName,
    //       prHierarchy
    //     );
    //     setShowEdu(false);
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };
  const handleSave = async (values, submitProps, formik) => {
    window.localStorage.setItem('page_Index', 'sub_Index');
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    const payload = {
      // board_id: board_id,
      subject_id: subject_id,
      description: values.sub_desc,
      group_institution_id: groupInstId,
      course_id: course_id,
    };
    try {
      // PORT-GSC-8: Add Subject to Group Study Circle

      const res = await postGroupStudyCircleSubject(study_circle_ID, payload);
      toast.success('subject added successfully');
      if (res.data.status === true) {
        // setOpen(false);
        console.log(shName, prHierarchy);
        onTableClick(
          window.localStorage.getItem('table_index'),
          window.localStorage.getItem('grpInst_id'),
          window.localStorage.getItem('table_study_circle_Selected_Name'),
          state.desc,
          shName,
          prHierarchy,
        );
        setState({ ...state, showHidePlus: false });
      }
      window.localStorage.setItem('page', 'nextScreen');
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
    // Axios.post(
    //   `${BASE_URL}/group_study_circles/${study_circle_ID}/subjects`,
    //   {
    //     // board_id: board_id,
    //     subject_id: subject_id,
    //     description: values.sub_desc,
    //     group_institution_id: groupInstId,
    //     course_id: course_id,
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("subject added successfully");
    //     if (res.data.status === true) {
    //       // setOpen(false);
    //       console.log(shName, prHierarchy);
    //       onTableClick(
    //         window.localStorage.getItem("table_index"),
    //         window.localStorage.getItem("grpInst_id"),
    //         window.localStorage.getItem("table_study_circle_Selected_Name"),
    //         state.desc,
    //         shName,
    //         prHierarchy
    //       );
    //       setState({ ...state, showHidePlus: false });
    //     }
    //     window.localStorage.setItem("page", "nextScreen");
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     // setTimeout(() => {
    //     //   window.location.reload(false);
    //     // }, 3000);
    //   });
  };

  const addStudyCircle = async (values, submitProps, formik) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    console.log(values);
    const payload = {
      name: values.study_circleName,
      incharge_educator_id: values?.incharge_studyCircle,
      // description:values.desc
    };
    try {
      const res = await createStudyCircle(school_id, payload);

      toast.success('study circle added successfully');
      if (res.data.status === true) {
        // setOpen(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }
    // Axios.post(
    //   `${BASE_URL}/institutions/${school_id}/study_circles`,
    //   {
    //     name: values.study_circleName,
    //     incharge_educator_id: values.incharge_studyCircle,
    //     // description:values.desc
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("study circle added successfully");
    //     if (res.data.status === true) {
    //       // setOpen(false);
    //       setTimeout(() => {
    //         window.location.reload(false);
    //       }, 3000);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };

  const addStudyCircleTab = async () => {
    console.log(state, 'studycirclestate');
    const payload = {
      name: state.study_circle_Selected_Name,
      incharge_educator_id: state?.incharge_grp_study_circle || 0,
      description: state.desc,
    };
    try {
      const res = await editStudyCircleDetails(school_id, study_circle_ID, payload);

      toast.success('Data Edited Successfully');
      callback_value = true;
      if (res.data.status === true) {
        // setOpen(false);
        setTimeout(() => {
          setState({
            ...state,
            study_circle_Selected_Name: state.study_circle_Selected_Name,
            desc: state.desc,
          });
        }, 3000);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }
    // Axios.put(
    //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}`,
    //   {
    //     name: state.study_circle_Selected_Name,
    //     incharge_educator_id: state.incharge_grp_study_circle,
    //     description: state.desc,
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("Data Edited Successfully");
    //     callback_value = true;
    //     if (res.data.status === true) {
    //       // setOpen(false);
    //       setTimeout(() => {
    //         setState({
    //           ...state,
    //           study_circle_Selected_Name: state.study_circle_Selected_Name,
    //           desc: state.desc,
    //         });
    //       }, 3000);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };

  const handleClosePopup = () => setErrorUpload(false);
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );
  const ErrModal = () => {
    let errHead = ['row Number', 'Student Name', 'Error'];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${errMsg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.row_number}</td>
                    <td>{elm.student_name}</td>
                    <td>{elm.result ? elm.result : elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  const NewScreen = () => {
    return (
      <div>
        {/* {setIsLoading ? (
        <div>
          <ReactLoading type="spin" color="var(--main-green)" className={styles.startLoadSpin} />
        </div>
      ) : null} */}
        <div className={styles.top}>
          <div className={styles.backBtn}>
            <div className={styles.bck} onClick={callBack}>
              <span className={styles.back}>
                <img src={require('../../../../assets/icons/back.png')} alt="back" />
              </span>
              <span className={styles.study}>Group Study Circle List</span>
            </div>
          </div>
        </div>
        {/* {loading ? (
          <div className={styles.loader}>
            <ReactBootStrap.Spinner animation="border" variant="success" />{" "}
            &nbsp;<span className={styles.loaderTxt}>Loading...</span>
          </div>
        ) : null} */}
        {loading ? <Example /> : null}
        <div className={styles.study_dtl}>
          <div className={styles.table_box_1}>
            <div className={styles.study_txt}>Group Study Circle</div>
            <Box className={styles.boxes} component="div">
              <Formik>
                {(formik) => {
                  return (
                    <Form className={styles.boxes}>
                      <div className={styles.box1}>
                        <label htmlFor="study_circle_name" className={styles.labels}>
                          Group Study Circle Name
                        </label>
                        <Box className={styles.std_name} component="div">
                          <Field
                            // name="study_circleName"
                            type="text"
                            className={styles.std_name}
                            id="study_circleName"
                            value={state.study_circle_Selected_Name}
                            onChange={(e) =>
                              setState({
                                ...state,
                                study_circle_Selected_Name: e.target.value,
                              })
                            }
                          />
                        </Box>
                      </div>
                      <div className={styles.box2}>
                        <label htmlFor="inchange_of_study_circle" className={styles.labels}>
                          Incharge of Group Study Circle
                        </label>
                        <Box className={styles.std_name} component="div">
                          <Field
                            as="select"
                            // type="text"
                            name="incharge_studyCircle"
                            className={styles.d_options}
                            value={state.incharge_grp_study_circle}
                            onChange={(e) => {
                              setState({
                                ...state,
                                incharge_grp_study_circle: e.target.value,
                              });
                            }}
                          >
                            <option value={state.incharge_grp_study_circle}>{state.incharge_grp_study_circle}</option>
                            );
                          </Field>
                        </Box>
                      </div>
                      <div className={styles.box2}>
                        <label htmlFor="inchange_of_study_circle" className={styles.labels}>
                          Description
                        </label>
                        <Box className={styles.std_name} component="div">
                          <Field
                            type="text"
                            name="desc"
                            className={styles.d_options}
                            value={state.desc}
                            onChange={(e) => {
                              setState({
                                ...state,
                                desc: e.target.value,
                              });
                            }}
                          />
                        </Box>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </div>
        </div>
        <div className={styles.file_container} style={{ margin: '-30px 0 -10px 0' }}>
          <Box className={styles.file_box} component="div">
            <div className={styles.file_text}>
              <label>Subjects ({state.subject_body.length})</label>
            </div>
            <div className={styles.box_btn_add}>
              <Box className={styles.btn_add} onClick={handleClickOpenAdd}>
                Add
              </Box>
            </div>
          </Box>
        </div>
        <div className={styles.subjectTable}>
          <div className={styles.table_box_1}>
            {loading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {loading === true ? (
                  <Example />
                ) : (
                  <div className={styles.schoolList_Table}>
                    <div className={styles.schoolList_Table_head + ' ' + styles.subHead}>
                      {state.subject_head.map((elm, key) => (
                        <div
                          key={key}
                          className={
                            elm === 'Group Educators'
                              ? styles.schoolList_Table_head_data_grp_edu + ' ' + styles.subHeadBody_grp_edu
                              : styles.schoolList_Table_head_data + ' ' + styles.subHeadBody
                          }
                        >
                          {elm}
                        </div>
                      ))}
                    </div>
                    {
                      <div className={styles.schoolList_Table_body}>
                        {state.subject_body.length === 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            No Data Found
                            <span role="img" aria-label="jsx-a11y/accessible-emoji">
                              😔
                            </span>
                          </div>
                        ) : (
                          state.subject_body.map((elm, key) => (
                            <div
                              key={key}
                              className={styles.schoolList_Table_body_row + ' ' + styles.subBodyRow}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className={styles.schoolList_Table_body_row_td2 + ' ' + styles.subBodyRow_td}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {elm.board_name}
                                </div>
                              </div>
                              <div className={styles.schoolList_Table_body_row_td2 + ' ' + styles.subBodyRow_td}>
                                {elm.course_name}
                              </div>
                              <div className={styles.schoolList_Table_body_row_td2 + ' ' + styles.subBodyRow_td}>
                                {elm.subject_name}
                              </div>
                              <div className={styles.schoolList_Table_body_row_td5 + ' ' + styles.subBody_educatroList}>
                                {elm.group_educator_list.map((el, key) => {
                                  return el.group_educator_name === null ? (
                                    ''
                                  ) : (
                                    <div key={key} className={styles.educator_name}>
                                      <span> {el.group_educator_name}</span>
                                      <Icon className={styles.crossArrow}>
                                        <img
                                          src={require('../../../../assets/icons/CrossDelete.png')}
                                          alt="deletebox"
                                          style={{
                                            cursor: 'pointer',
                                            width: '12px',
                                            height: '12px',
                                          }}
                                          onClick={(e) =>
                                            handleEducatorDelete(e, elm.subject_id, el.group_study_circle_subject_educator_id)
                                          }
                                        />
                                      </Icon>
                                    </div>
                                  );
                                })}
                                {state.subId === elm.subject_id && showEdu ? (
                                  <Box
                                    component="select"
                                    className={styles.options}
                                    onChange={(e) => {
                                      addEducatorToSubject(e);
                                    }}
                                  >
                                    <option>{'Educator List'}</option>
                                    {state.drop_edu.map((el, key) => (
                                      <option value={el.group_educator_id + ' ' + el.group_educator_name} key={key}>
                                        {el.group_educator_name}
                                      </option>
                                    ))}
                                  </Box>
                                ) : elm.group_educator_list.length > 0 ? (
                                  <Icon className={styles.plus_icon}>
                                    <img
                                      src={require('../../../../assets/icons/plus_icon.png')}
                                      alt="plus_icon"
                                      style={{
                                        cursor: 'pointer',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                      onClick={(e) => handleClickIcon(e, elm.subject_id)}
                                    />
                                  </Icon>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.subBodyRow_td}>
                                <Icon
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                  }}
                                >
                                  <img
                                    src={require('../../../../assets/icons/DeleteBox.png')}
                                    alt="deletebox"
                                    style={{
                                      cursor: 'pointer',
                                      width: '30px',
                                      height: '30px',
                                    }}
                                    onClick={(e) => handleSubjectDelete(e, elm.group_study_circle_subject_id)}
                                  />
                                </Icon>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* <div
          className={styles.file_container}
          style={{ margin: "-30px 0 -10px 0" }}
        >
          <Box className={styles.file_box} component="div">
            <div className={styles.file_text}>
              <label>
                Group Educator Details ({state.educator_body.length})
              </label>
            </div>
            <div className={styles.box_btn_add}>
              <Box className={styles.btn_add} onClick={handleClickOpenAdd}>
                Add
              </Box>
            </div>
          </Box>
        </div> */}
        {/* <div className={styles.educatorTable}>
          <div className={styles.table_box_1}>
            {isLoading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {isLoading === true ? (
                  <Example />
                ) : (
                  <div className={styles.schoolList_Table}>
                    <div className={styles.schoolList_Table_head}>
                      {state.educator_head.map((elm, key) => (
                        <div
                          key={key}
                          className={styles.schoolList_Table_head_data}
                        >
                          {elm}
                        </div>
                      ))}
                    </div>
                    {
                      <div className={styles.schoolList_Table_body}>
                        {state.educator_body.length === 0 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "bold",
                            }}
                          >
                            No Data Found
                            <span
                              role="img"
                              aria-label="jsx-a11y/accessible-emoji"
                            >
                              😔
                            </span>
                          </div>
                        ) : (
                          state.educator_body.map((elm, key) => (
                            <div
                              key={key}
                              className={styles.schoolList_Table_body_row}
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className={styles.schoolList_Table_body_row_td2}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    src={elm.cmn_teacher_profile_pic_file_path}
                                    alt="avatar"
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      display: "flex",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                      marginLeft: "15px",
                                    }}
                                  />
                                  <div>{elm.cmn_teacher_name}</div>
                                </div>
                              </div>
                              <div
                                className={styles.schoolList_Table_body_row_td3}
                              >
                                {elm.cmn_syllabus_name}
                              </div>
                              <div
                                className={styles.schoolList_Table_body_row_td3}
                              >
                                {elm.qb_class_number}
                              </div>
                              <div
                                className={styles.schoolList_Table_body_row_td3}
                              >
                                {elm.cmn_subject_name}
                              </div>
                              <div
                                className={styles.schoolList_Table_body_row_td3}
                              >
                                {"-"}
                              </div>
                              <div
                                className={styles.schoolList_Table_body_row_td3}
                              >
                                <Icon
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    src={require("../../../../assets/icons/DeleteBox.png")}
                                    alt="deletebox"
                                    style={{
                                      cursor: "pointer",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    data-value={
                                      elm.cmn_study_circle_teacher_subject_id
                                    }
                                    // onClick={(e) => handleDelete(e)}
                                  />
                                </Icon>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div> */}
        <div className={styles.file_container}>
          <Box className={styles.file_box} component="div">
            <div className={styles.file_text}>
              <label>Study Circle List ({state.subStudyCircle_body.length})</label>
            </div>
            <div className={styles.file_opt}>
              <Box component="div" onClick={(e) => downloadFile(e, 'downloadStd')} className={styles.file_download}>
                <img
                  src={require('../../../../assets/icons/down.png')}
                  alt="down"
                  style={{
                    marginRight: '10px',
                    width: '13px',
                    height: '16px',
                  }}
                />
                Download Studycircle List
              </Box>
              <Formik>
                {(formik) => {
                  return (
                    <div>
                      <Form>
                        <Field
                          type="file"
                          id="filetouploadstdlist"
                          name="filetouploadstdlist"
                          onChange={(e) => {
                            uploadFile(e, 'uploadStd');
                            e.target.value = null;
                          }}
                          style={{ display: 'none' }}
                        />
                        <Box component="div" className={styles.file_upload}>
                          <label
                            href="#"
                            htmlFor="filetouploadstdlist"
                            style={{
                              borderRadius: '10px',
                              width: '220px',
                              height: '32px',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={require('../../../../assets/icons/up.png')}
                              alt="up"
                              style={{
                                marginRight: '10px',
                                width: '13px',
                                height: '16px',
                              }}
                            />
                            Upload Studycircle List
                          </label>
                        </Box>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </div>
        <div>
          <div className={styles.table_box_1}>
            {loading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {loading === true ? (
                  <Example />
                ) : (
                  <div className={styles.schoolList_Table}>
                    <div className={styles.schoolList_Table_head}>
                      <div className={styles.schoolList_Table_head_data_cir}>Sr.No</div>
                      <div className={styles.schoolList_Table_head_data}>Study Circle</div>
                      <div className={styles.schoolList_Table_head_data_inst2}>Institution</div>
                      <div className={styles.schoolList_Table_head_data_disp}>Institution Display Code</div>
                      <div className={styles.schoolList_Table_head_data_cir}>Students</div>
                      <div className={styles.schoolList_Table_head_data}>Place</div>
                      <div className={styles.schoolList_Table_head_data}>Pincode</div>
                      <div className={styles.schoolList_Table_head_data_cir}>Country</div>
                    </div>
                    {
                      <div className={styles.schoolList_Table_body}>
                        {state.subStudyCircle_body.length === 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            No Data Found
                            <span role="img" aria-label="jsx-a11y/accessible-emoji">
                              😔
                            </span>
                          </div>
                        ) : (
                          state.subStudyCircle_body.map((elm, key) => (
                            <div key={key} className={styles.schoolList_Table_body_row} style={{ cursor: 'pointer' }}>
                              <div className={styles.schoolList_Table_body_row_td3}>{elm.s_no}</div>
                              <div className={styles.schoolList_Table_body_row_td2}>{elm.study_circle_name}</div>

                              <div className={styles.schoolList_Table_body_row_td5}>{elm.institution_name}</div>
                              <div className={styles.schoolList_Table_body_row_td2}>{elm.institution_display_code}</div>
                              <div className={styles.schoolList_Table_body_row_td3}>{elm.student_count}</div>
                              <div className={styles.schoolList_Table_body_row_td2}>{elm.place}</div>
                              <div className={styles.schoolList_Table_body_row_td2}>{elm.pincode}</div>
                              <div className={styles.schoolList_Table_body_row_td3}>{elm.county_code}</div>
                            </div>
                          ))
                        )}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
          <Box className={styles.buttons}>
            <div className={styles.buttons_div}>
              <Box component="button" className={styles.btn_cancel}>
                Cancel
              </Box>
              <Box component="button" className={styles.btn_save} onClick={addStudyCircleTab}>
                Save
              </Box>
            </div>
          </Box>
        </div>
        <ModalComponent show={state.showHidePlus} onClick={handleClosePlus} className="addSubModal">
          <Box className={styles.main_box} component="div" style={{ marginTop: '-20px' }}>
            <Formik
              initialValues={initialFormBox || state}
              validationSchema={validationFormBox}
              onSubmit={handleSave}
              enableReinitialize
            >
              {(formik) => {
                return (
                  <Box component="div" style={{ width: '100%', height: 'auto' }}>
                    <Form className={styles.modal_Form}>
                      <label htmlFor="board">Board</label>
                      <Box className={styles.main_box_1} component="div">
                        <Field
                          as="select"
                          name="board"
                          id="board"
                          value={undefined}
                          className={styles.d_options}
                          onChange={(e) => changedBoard(e, 'board')}
                        >
                          <option value="-1">{'Board'}</option>
                          {state.drop_board.map((el, key) => (
                            <option value={el.board_id} key={key}>
                              {el.display_name}
                            </option>
                          ))}
                        </Field>
                      </Box>
                      <div className={styles.hierarchy}>Filter with hierarchy</div>
                      <label htmlFor="course">Course</label>
                      <Box className={styles.main_box_2} component="div">
                        <Field
                          as="select"
                          name="course"
                          id="course"
                          value={undefined}
                          className={styles.d_options}
                          onChange={(e) => changedBoard(e, 'course')}
                        >
                          <option value="-1">{'Course'}</option>
                          {state.drop_course.map((el, key) => (
                            <option value={el.course_id} key={key}>
                              {el.display_name}
                            </option>
                          ))}
                        </Field>
                      </Box>
                      <label htmlFor="subject">Subject</label>

                      <Box className={styles.main_box_3} component="div">
                        <Field
                          as="select"
                          name="subject"
                          id="subject"
                          value={undefined}
                          // value={editVal ? selectedBoard() : undefined}
                          // onChange={(e)=>setState({...state,subject:e.terget.value})}
                          onChange={(e) => changedBoard(e, 'subject')}
                          className={styles.d_options}
                        >
                          <option value="-1">{'Subject'}</option>
                          {state.drop_subject.map((el, key) => (
                            <option value={el.subject_id} key={key}>
                              {el.display_name}
                            </option>
                          ))}
                        </Field>
                      </Box>
                      <label htmlFor="board">Description</label>
                      <Box className={styles.main_box_1} component="div" style={{ height: '60px' }}>
                        <Field
                          name="sub_desc"
                          // placeholder="Topic Display Name"
                          component="textarea"
                          className={styles.B_Field}
                          id="sub_desc"
                          value={state.sub_desc}
                          onChange={(e) => {
                            setState({
                              ...state,
                              sub_desc: e.target.value,
                            });
                          }}
                        />
                      </Box>
                      <Box className={styles.buttonss}>
                        <button className={styles.cancelBtn} onClick={handleClosePlus} type="reset">
                          Cancel
                        </button>
                        <button
                          className={styles.saveBtn}
                          type="submit"
                          onClick={(e) => {
                            let sub_desc_val = document.getElementById('sub_desc').value;
                            formik.setFieldValue('sub_desc', sub_desc_val);
                            setNextPage(true);
                          }}
                        >
                          Save
                        </button>
                      </Box>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        </ModalComponent>
        <div></div>
      </div>
    );
  };

  return (
    <div>
      {nextPage === false ? (
        <Box className={styles.container}>
          <Box className={styles.top_box} component="div">
            <Box className={styles.add_box} component="div" onClick={handleClickOpen}>
              Add Group Study Circle
            </Box>
            <div className={styles.file_opt}>
              <Box component="div" onClick={(e) => downloadFile(e, 'downloadGsc')} className={styles.file_download}>
                <img
                  src={require('../../../../assets/icons/down.png')}
                  alt="down"
                  style={{
                    marginRight: '10px',
                    width: '13px',
                    height: '16px',
                  }}
                />
                Download Group Studycircle List
              </Box>
              <Formik>
                {(formik) => {
                  return (
                    <div>
                      <Form>
                        <Field
                          type="file"
                          id="filetoupload"
                          name="filetoupload"
                          onChange={(e) => {
                            uploadFile(e, 'uploadGsc');
                            e.target.value = null;
                          }}
                          style={{ display: 'none' }}
                        />
                        <Box component="div" className={styles.file_upload}>
                          <label
                            href="#"
                            htmlFor="filetoupload"
                            style={{
                              borderRadius: '10px',
                              width: '220px',
                              height: '32px',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={require('../../../../assets/icons/up.png')}
                              alt="up"
                              style={{
                                marginRight: '10px',
                                width: '13px',
                                height: '16px',
                              }}
                            />
                            Upload Group Studycircle List
                          </label>
                        </Box>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </Box>
          <Box>
            <div className={styles.table_box_list}>
              {loading === true ? (
                <Example />
              ) : (
                <div className={styles.table_box}>
                  {loading === true ? (
                    <Example />
                  ) : (
                    <div className={styles.schoolList_Table}>
                      <div className={styles.schoolList_Table_head}>
                        <div className={styles.schoolList_Table_head_data}>Group Study Circle</div>
                        <div className={styles.schoolList_Table_head_data_inst}>Group Institution</div>
                        <div className={styles.schoolList_Table_head_data_cir}>Study Circle</div>
                        <div className={styles.schoolList_Table_head_data_cir}>Course</div>
                        <div className={styles.schoolList_Table_head_data_cir}>Subjects</div>
                        <div className={styles.schoolList_Table_head_data_cir}>Students</div>
                        <div className={styles.schoolList_Table_head_data_edu}>Group Educators</div>
                        <div className={styles.schoolList_Table_head_data}>Description</div>
                      </div>
                      {
                        <div className={styles.schoolList_Table_body}>
                          {state.study_circle_t_body === 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'bold',
                              }}
                            >
                              No Data Found
                              <span role="img" aria-label="jsx-a11y/accessible-emoji">
                                😔
                              </span>
                            </div>
                          ) : (
                            state.study_circle_t_body.map((elm, key) => (
                              <div
                                className={styles.schoolList_Table_body_row}
                                key={key}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  onTableClick(
                                    elm.group_study_circle_id,
                                    elm.group_institution_id,
                                    elm.group_study_circle_name,
                                    elm.descriptionl,
                                    elm.group_institution_short_name,
                                    elm.parent_hierarchy_path,
                                  );
                                }}
                              >
                                <div className={styles.schoolList_Table_body_row_td2}>{elm.group_study_circle_name}</div>
                                <div className={styles.schoolList_Table_body_row_td4}>{elm.group_institution_name}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.study_circle_count}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.course_count}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.subject_count}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.student_count}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.group_educator_count}</div>
                                <div className={styles.schoolList_Table_body_row_td2}>{elm.description}</div>
                              </div>
                            ))
                          )}
                        </div>
                      }
                    </div>
                  )}
                </div>
              )}
            </div>
          </Box>
          <ModalComponent show={state.showHide} onClick={handleClose} className="addEducatorStdy">
            <Box className={styles.main_box} component="div">
              <Formik
                initialValues={initialBox || state}
                validationSchema={validationBox}
                onSubmit={addStudyCircle}
                enableReinitialize
              >
                {(formik) => {
                  return (
                    <Box component="div" style={{ width: '100%', height: 'auto' }}>
                      <Form className={styles.modal_Form}>
                        <Box component="div" style={{ width: '68%', height: 'auto' }}>
                          <div className={styles.modal_Text}>Group Study Circle Name</div>
                        </Box>
                        <Box className={styles.main_box_1} component="div">
                          <Field name="study_circleName" type="text" className={styles.B_Field} id="study_circleName" />
                        </Box>
                        <Box component="div" style={{ width: '68%', height: 'auto' }}>
                          <div className={styles.modal_Text}>Incharge of Study Circle</div>
                        </Box>
                        <Box className={styles.main_box_2} component="div">
                          <Field as="select" name="incharge_studyCircle" className={styles.d_options}>
                            <option>{'Select'}</option>
                            {state.incharge_data.map((elm, key) => {
                              return (
                                <option value={elm.id} key={key}>
                                  {elm.name}
                                </option>
                              );
                            })}
                          </Field>
                        </Box>
                        {/* <Box
                          component="div"
                          style={{ width: "68%", height: "auto" }}
                        >
                          <div className={styles.modal_Text}>
                            Description
                          </div>
                        </Box>
                        <Box className={styles.main_box_2} style={{height: "60px" }} component="div">
                        <Field
                          name="desc"
                          // placeholder="Topic Display Name"
                          component="textarea"
                          className={styles.B_Field}
                          id="desc"
                          value={state.desc}
                          onChange={(e) => {
                            setState({
                              ...state,
                              desc: e.target.value,
                            });
                          }}
                      />
                        </Box> */}
                        <Box className={styles.buttonss}>
                          <button className={styles.cancelBtn} onClick={handleClose} type="reset">
                            Cancel
                          </button>
                          <button
                            className={styles.saveBtn}
                            type="submit"
                            disabled={!formik.isValid || formik.isSubmitting}
                            // onClick={(e) => {
                            //   let desc_val = document.getElementById("desc").value;
                            //   formik.setFieldValue("desc", desc_val);
                            // }}
                          >
                            Save
                          </button>
                        </Box>
                      </Form>
                    </Box>
                  );
                }}
              </Formik>
            </Box>
          </ModalComponent>
        </Box>
      ) : (
        NewScreen()
      )}
      {/* <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      /> */}
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default StudyCircle;
