/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import styles from './StudyCircleStyle.module.css';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Avatar, Icon } from '@material-ui/core';
import { ModalComponent, Dialogbox } from '../../../../components/index';
import { saveAs } from 'file-saver';
import * as ReactBootStrap from 'react-bootstrap';
import ReactLoading from 'react-loading';
import Select from 'react-dropdown-select';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
import { getEducatorsBySchoolId } from '../../../../api/educator';
import {
  listStudyCircles,
  createStudyCircle,
  editStudyCircleDetails,
  downloadStudentListExcel,
  uploadStudentListCSV,
  getStudentListTableData,
  addSubjectToStudyCircle,
  getSubjectsForStudyCircle,
  removeSubjectFromStudyCircle,
  getEducatorsForSubjectInStudyCircle,
  addEducatorToSubjectInStudyCircle,
  deleteEducatorFromSubjectInStudyCircle,
} from '../../../../api/studyCircle';
import { getAllAcademicPeriods } from '../../../../api/academicPeriod';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';
import { getAllSubjectsByBoardId } from '../../../../api/InstitutionSubjects';

const initialBox = {
  study_circleName: '',
  incharge_studyCircle: '',
  desc: '',
};

const validationBox = Yup.object({
  study_circleName: Yup.string(),
  incharge_studyCircle: Yup.string(),
  desc: Yup.string(),
});

const initialFormBox = {
  board: '',
  course: '',
  subject: '',
  sub_desc: '',
};

const validationFormBox = Yup.object({
  boarde: Yup.string(),
  course: Yup.string(),
  subject: Yup.string(),
  sub_desc: Yup.string(),
});

var study_circle_ID;
// var cmn_study_circle_teacher_subject_ID;
var callback_value;
var board_id = -1;
var course_id = -1;
var subject_id = -1;

const StudyCircle = () => {
  const boardId = window.localStorage.getItem('board_id') || JSON.parse(window.localStorage.getItem('institutionData'))?.board_id;
  let optionData = [
    {
      label: window.localStorage.getItem('academic_period_name'),
      value: window.localStorage.getItem('academic_period_name'),
    },
  ];
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState('');
  const [errMsg, setErrMsg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdu, setShowEdu] = useState(false);
  const [instituteData, setInstituteData] = useState([]);
  const [selectedAcademicPeriod, setSelectedAcademicPeriod] = useState([
    {
      label: window.localStorage.getItem('academic_period_name'),
      value: window.localStorage.getItem('academic_period_name'),
    },
  ]);
  const [state, setState] = useState({
    study_circle_t_head: ['Study Circle Name', 'Course', 'Subjects', 'Students', 'Educators', 'Incharge', 'Description'],
    student_head: ['Roll No', 'Student', 'Institution Student ID', 'Institution Defined Group'],
    educator_head: ['Educator', 'Board', 'Grade', 'Subject', 'More details', ''],
    subject_head: ['Board', 'Course', 'Subject', 'Educators', ''],
    study_circle_t_body: [],
    educator_body: [],
    student_body: [],
    subject_body: [],
    drop_board: [],
    drop_course: [],
    drop_subject: [],
    drop_edu: [],
    incharge_data: [],
    selected_class: [],
    board: '',
    classchange: '',
    subjectchange: '',
    study_circle_Selected_Name: '',
    study_circle_Index: '',
    incharge_educator_Selected_id: '',
    desc: '',
    sub_desc: '',
    subId: '',
  });
  console.log(instituteData);
  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 7);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  //   console.log(process.env.REACT_APP_ACCESS_KEY_ID);
  // console.log(process.env.REACT_APP_END_POINT);
  // console.log(process.env.REACT_APP_SECRET_KEY);

  const fetchResult = async () => {
    try {
      // PORT-IB-5: Retrieve list of boards of an institution

      const getBoard = await getBoardsByNameInInstitution(school_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards`,
      //   Auth
      // );

      // PORT-SC-1: List the study circles in tabular format
      const getTableData = await listStudyCircles(school_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/study_circles/detailed-info`,
      //   Auth
      // );

      // PORT-EDU-3: Retrieve list of educators for an institution

      const getInchargeData = await getEducatorsBySchoolId(school_id);
      // const getInchargeData = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/educators`,
      //   Auth
      // );

      setIsLoading(false);
      setState({
        ...state,
        study_circle_t_body: getTableData.data.data,
        drop_board: getBoard.data.data,
        incharge_data: getInchargeData.data.data,
      });
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };
  const fetchInstituteList = async () => {
    try {
      // PORT-AP-03: Get all academic periods for a board
      if (boardId) {
        const getInstitutionList = await getAllAcademicPeriods(boardId);
        // await Axios.get(
        //   `${BASE_URL}/academic_periods/all?board_id=${boardId}`,
        //   Auth
        // );

        setInstituteData(
          getInstitutionList?.data?.data?.map((i) => ({
            ...i,
            board_id: boardId,
          })),
        );
        // setSelectedAcademicPeriod(
        //   getInstitutionList?.data?.data?.filter((i) => i?.is_current === true)?.map((j) => ({ ...j, board_id: boardId })),
        // );
        setSelectedAcademicPeriod(optionData);
      }
    } catch (err) {
      console.log('Error', err);
    }
  };
  useEffect(() => {
    if (nextPage === true && boardId) {
      fetchInstituteList();
    }
  }, [nextPage]);

  useEffect(() => {
    setIsLoading(true);
    fetchResult();

    // fetch institute info on page load
    fetchInstituteList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTableClick = async (index, study_circle_Selected_Name, incharge_educator_Selected_id, desc) => {
    study_circle_ID = index;
    window.localStorage.setItem('table_index', index);
    window.localStorage.setItem('table_study_circle_Selected_Name', study_circle_Selected_Name);
    window.localStorage.setItem('table_incharge_educator_Selected_id', incharge_educator_Selected_id);
    // localStorage.setItem("primary_qb_class_id",primary_qb_class_id)
    const studentList = await getStudentListTableData(
      school_id,
      study_circle_ID,
      window.localStorage.getItem('academic_period_name'),
    );
    // await Axios.get(
    //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/students/detailed-info?academic_period=${selectedAcademicPeriod?.[0]?.academic_period_name}`,
    //   Auth
    // );
    const subjectList = await getSubjectsForStudyCircle(school_id, study_circle_ID);
    // await Axios.get(
    //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/subjects/detailed-info`,
    //   Auth
    // );

    setState({
      ...state,
      // drop_edu: educatorList.data,
      subject_body: subjectList.data.data,
      student_body: studentList.data.data,
      study_circle_Selected_Name: study_circle_Selected_Name,
      incharge_educator_Selected_id: incharge_educator_Selected_id,
      desc: desc,
      showHidePlus: false,
    });

    window.localStorage.setItem('page_Index', 'sub_Index');
    setNextPage(true);
  };

  const handleSubjectDelete = async (e, id) => {
    window.localStorage.setItem('page_Index', 'sub_Index');
    try {
      await removeSubjectFromStudyCircle(school_id, study_circle_ID, id);
      toast.success('data deleted successfully');
      onTableClick(
        window.localStorage.getItem('table_index'),
        window.localStorage.getItem('table_study_circle_Selected_Name'),
        window.localStorage.getItem('table_incharge_educator_Selected_id'),
      );
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }
    // await Axios.delete(
    //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/subjects/${id}`,
    //   Auth
    // );
    //   .then((response) => {
    //     toast.success("data deleted successfully");
    //     onTableClick(
    //       window.localStorage.getItem("table_index"),
    //       window.localStorage.getItem("table_study_circle_Selected_Name"),
    //       window.localStorage.getItem("table_incharge_educator_Selected_id")
    //     );
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };

  const handleClosePlus = (value) => {
    setState({ ...state, showHidePlus: false });
  };

  const handleClickOpenAdd = () => {
    setState({ ...state, showHidePlus: true });
  };

  // const handleClickOpenPlus = () => {
  //   setState({ ...state, showHidePlus: true });
  // };
  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = () => {
    setState({ ...state, showHide: true });
  };

  const callBack = () => {
    callback_value ? window.location.reload(false) : (callback_value = null);
    setNextPage(false);
    setState({ ...state, showHide: false });
  };

  const changedBoard = async (e, selectedvalue) => {
    try {
      if (selectedvalue === 'board') {
        board_id = e.target.value;
      } else if (selectedvalue === 'course') {
        course_id = e.target.value;
      } else if (selectedvalue === 'subject') {
        subject_id = e.target.value;
      }
      // PORT-IC-4: Retrieve list of courses of an institution
      const getCourse = await getCoursesListOfAnInstitution(school_id, board_id, Auth);
      // const getCourse = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses`,
      //   Auth
      // );

      //  PORT-IS-9: Retrieve list of subjects for an institutions for given board / course
      console.log('getAllSubjectsByBoardId');
      const getSubject = await getAllSubjectsByBoardId(school_id, board_id, course_id, '');
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects`,
      //   Auth
      // );

      setState((prevState) => ({
        ...prevState,
        drop_course: getCourse.data.data,
        drop_subject: getSubject.data.data,
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const downloadFile = async () => {
    if (selectedAcademicPeriod?.length > 0) {
      try {
        const response = await downloadStudentListExcel(school_id, study_circle_ID, selectedAcademicPeriod?.[0].value);
        let filename = response.headers['content-disposition'].split('=')[1];
        var blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });
        saveAs(blob, filename);
        toast.success('file downloaded');
        // await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/students/excel-export?academic_period_name=${selectedAcademicPeriod?.[0]?.academic_period_name}`,
        //   {
        //     ...Auth,
        //     responseType: "arraybuffer",
        //   }
        // ).then((response) => {
        //   // console.log(response)
        //   let filename = response.headers["content-disposition"].split("=")[1];
        //   var blob = new Blob([response.data], {
        //     type: "application/octet-stream",
        //   });
        //   saveAs(blob, filename);
        //   toast.success("file downloaded");
        // });
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        toast.error('No records');
      }
    } else {
      toast.error('Please Select Acadamic Period');
    }
  };
  const uploadFile = async (e) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);
    formdata.append('filetoupload', file);
    try {
      await uploadStudentListCSV(school_id, study_circle_ID, selectedAcademicPeriod?.[0]?.value, formdata);
      toast.success('File Uploaded Successfully');
      setLoading(false);
      onTableClick(
        window.localStorage.getItem('table_index'),
        window.localStorage.getItem('table_study_circle_Selected_Name'),
        window.localStorage.getItem('table_incharge_educator_Selected_id'),
      );
    } catch (err) {
      if (err) {
        setErrorUpload(true);
        setLoading(false);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        toast.error(`oops! error while upload file  check in the modal..`);
        setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      }
    }
    // Axios({
    //   url: `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/students/excel-import?academic_period_name=${selectedAcademicPeriod?.[0]?.academic_period_name}`,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then((res) => {
    //     toast.success("File Uploaded Successfully");
    //     setLoading(false);
    //     onTableClick(
    //       window.localStorage.getItem("table_index"),
    //       window.localStorage.getItem("table_study_circle_Selected_Name"),
    //       window.localStorage.getItem("table_incharge_educator_Selected_id")
    //     );
    //   })
    //   .catch((err) => {
    //     if (err) {
    //       setErrorUpload(true);
    //       setLoading(false);
    //       if (err.response && err.response.data) {
    //         console.log(err.response.data.message);
    //         toast.error(err.response.data.message);
    //       }
    //       toast.error(`oops! error while upload file  check in the modal..`);
    //       setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
  };

  // console.log(study_circle_ID)

  const handleClickIcon = async (e, val) => {
    subject_id = val;
    try {
      const educatorList = await getEducatorsForSubjectInStudyCircle(school_id, study_circle_ID, subject_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/subjects/${subject_id}/educators`,
      //   Auth
      // );
      setState((prevState) => ({
        ...prevState,
        subId: val,
        drop_edu: educatorList.data.data,
      }));
      setShowEdu(true);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const addEducatorToSubject = async (e) => {
    let values = e.target.value.split(' ');
    const payload = {
      educator_id: values[0],
      description: values[1],
    };
    try {
      const res = await addEducatorToSubjectInStudyCircle(school_id, study_circle_ID, subject_id, payload);
      toast.success('Educator added successfully');
      if (res.data.status === true) {
        // setOpen(false);
        onTableClick(
          window.localStorage.getItem('table_index'),
          window.localStorage.getItem('table_study_circle_Selected_Name'),
          window.localStorage.getItem('table_incharge_educator_Selected_id'),
        );
        setState({ ...state, showHidePlus: false });
      }
      setShowEdu(false);
      window.localStorage.setItem('page', 'nextScreen');
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }

    // Axios.post(
    //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/subjects/${subject_id}/educators`,
    //   {
    //     educator_id: values[0],
    //     description: values[1],
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("Educator added successfully");
    //     if (res.data.status === true) {
    //       // setOpen(false);
    //       onTableClick(
    //         window.localStorage.getItem("table_index"),
    //         window.localStorage.getItem("table_study_circle_Selected_Name"),
    //         window.localStorage.getItem("table_incharge_educator_Selected_id")
    //       );
    //       setState({ ...state, showHidePlus: false });
    //     }
    //     setShowEdu(false);
    //     window.localStorage.setItem("page", "nextScreen");
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };

  const handleEducatorDelete = async (e, subId, subEduId) => {
    // console.log(subjectId,subEduId)
    window.localStorage.setItem('page_Index', 'sub_Index');
    try {
      await deleteEducatorFromSubjectInStudyCircle(school_id, study_circle_ID, subId, subEduId);
      toast.success('data deleted successfully');
      onTableClick(
        window.localStorage.getItem('table_index'),
        window.localStorage.getItem('table_study_circle_Selected_Name'),
        window.localStorage.getItem('table_incharge_educator_Selected_id'),
      );
      setShowEdu(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }

    // await Axios.delete(
    //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/subjects/${subId}/educators/${subEduId}`,
    //   Auth
    // )
    //   .then((response) => {
    //     toast.success("data deleted successfully");
    //     onTableClick(
    //       window.localStorage.getItem("table_index"),
    //       window.localStorage.getItem("table_study_circle_Selected_Name"),
    //       window.localStorage.getItem("table_incharge_educator_Selected_id")
    //     );
    //     setShowEdu(false);
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };
  const handleSave = async (values, submitProps, formik) => {
    window.localStorage.setItem('page_Index', 'sub_Index');
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    const payload = {
      subject_id: subject_id,
      description: values.sub_desc,
      course_id: course_id,
    };
    try {
      const res = await addSubjectToStudyCircle(school_id, study_circle_ID, payload);
      toast.success('subject added successfully');
      if (res.data.status === true) {
        // setOpen(false);
        onTableClick(
          window.localStorage.getItem('table_index'),
          window.localStorage.getItem('table_study_circle_Selected_Name'),
          window.localStorage.getItem('table_incharge_educator_Selected_id'),
        );
        setState({ ...state, showHidePlus: false });
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }

    // Axios.post(
    //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}/subjects`,
    //   {
    //     subject_id: subject_id,
    //     description: values.sub_desc,
    //     course_id: course_id,
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("subject added successfully");
    //     if (res.data.status === true) {
    //       // setOpen(false);
    //       onTableClick(
    //         window.localStorage.getItem("table_index"),
    //         window.localStorage.getItem("table_study_circle_Selected_Name"),
    //         window.localStorage.getItem("table_incharge_educator_Selected_id")
    //       );
    //       setState({ ...state, showHidePlus: false });
    //     }
    //     window.localStorage.setItem("page", "nextScreen");
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };

  const addStudyCircle = async (values, submitProps, formik) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    const payload = {
      name: values.study_circleName,
      incharge_educator_id: values.incharge_studyCircle,
      description: values.desc,
    };
    try {
      const res = await createStudyCircle(school_id, payload);

      toast.success('study circle added successfully');
      if (res.data.status === true) {
        setIsLoading(true);
        fetchResult();
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
    // console.log(values)
    // Axios.post(
    //   `${BASE_URL}/institutions/${school_id}/study_circles`,
    //   {
    //     name: values.study_circleName,
    //     incharge_educator_id: values.incharge_studyCircle,
    //     description: values.desc,
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("study circle added successfully");
    //     if (res.data.status === true) {
    //       setIsLoading(true);
    //       fetchResult();
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     // setTimeout(() => {
    //     // window.location.reload(false);
    //     // }, 3000);
    //   });
  };

  const addStudyCircleTab = async () => {
    const payload = {
      name: state.study_circle_Selected_Name,
      incharge_educator_id: state.incharge_educator_Selected_id,
      description: state.desc,
    };
    try {
      const res = await editStudyCircleDetails(school_id, study_circle_ID, payload);

      toast.success('Data Edited Successfully');
      callback_value = true;
      if (res.data.status === true) {
        // setOpen(false);
        setTimeout(() => {
          setState({
            ...state,
            study_circle_Selected_Name: state.study_circle_Selected_Name,
            desc: state.desc,
          });
        }, 3000);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }

    // Axios.put(
    //   `${BASE_URL}/institutions/${school_id}/study_circles/${study_circle_ID}`,
    //   {
    //     name: state.study_circle_Selected_Name,
    //     incharge_educator_id: state.incharge_educator_Selected_id,
    //     description: state.desc,
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("Data Edited Successfully");
    //     callback_value = true;
    //     if (res.data.status === true) {
    //       // setOpen(false);
    //       setTimeout(() => {
    //         setState({
    //           ...state,
    //           study_circle_Selected_Name: state.study_circle_Selected_Name,
    //           desc: state.desc,
    //         });
    //       }, 3000);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   });
  };

  const handleClosePopup = () => setErrorUpload(false);
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );
  const ErrModal = () => {
    let errHead = ['row Number', 'Student Name', 'Error'];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.row_number}</td>
                    <td>{elm.student_name}</td>
                    <td>{elm.result ? elm.result : elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  const NewScreen = () => {
    return (
      <div>
        <div className={styles.top}>
          <div className={styles.backBtn}>
            <div className={styles.bck} onClick={callBack}>
              <span className={styles.back}>
                <img src={require('../../../../assets/icons/back.png')} alt="back" />
              </span>
              <span className={styles.study}>Study Circle List</span>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={styles.loader}>
            <ReactBootStrap.Spinner animation="border" variant="success" /> &nbsp;
            <span className={styles.loaderTxt}>Loading...</span>
          </div>
        ) : null}
        <div className={styles.study_dtl}>
          <div className={styles.table_box_1}>
            <div className={styles.study_txt}>Study Circle</div>
            <Box className={styles.boxes} component="div">
              <Formik>
                {(formik) => {
                  return (
                    <Form className={styles.boxes}>
                      <div className={styles.box1}>
                        <label htmlFor="study_circle_name" className={styles.labels}>
                          Study Circle Name
                        </label>
                        <Box className={styles.std_name} component="div">
                          <Field
                            // name="study_circleName"
                            type="text"
                            className={styles.std_name}
                            id="study_circleName"
                            value={state.study_circle_Selected_Name}
                            onChange={(e) =>
                              setState({
                                ...state,
                                study_circle_Selected_Name: e.target.value,
                              })
                            }
                          />
                        </Box>
                      </div>
                      <div className={styles.box2}>
                        <label htmlFor="inchange_of_study_circle" className={styles.labels}>
                          Incharge of Study Circle
                        </label>
                        <Box className={styles.std_name} component="div">
                          <Field
                            as="select"
                            // type="text"
                            name="incharge_studyCircle"
                            className={styles.d_options}
                            value={state.incharge_educator_Selected_id}
                            onChange={(e) => {
                              setState({
                                ...state,
                                incharge_educator_Selected_id: e.target.value,
                              });
                            }}>
                            {state.incharge_data.map((elm, key) => {
                              return (
                                <option value={elm.id} key={key}>
                                  {elm.name}
                                </option>
                              );
                            })}
                          </Field>
                        </Box>
                      </div>
                      <div className={styles.box2}>
                        <label htmlFor="inchange_of_study_circle" className={styles.labels}>
                          Description
                        </label>
                        <Box className={styles.std_name} component="div">
                          <Field
                            type="text"
                            name="desc"
                            className={styles.d_options}
                            value={state.desc}
                            onChange={(e) => {
                              setState({
                                ...state,
                                desc: e.target.value,
                              });
                            }}
                          />
                        </Box>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </div>
        </div>
        <div className={styles.file_container} style={{ margin: '-30px 0 -10px 0' }}>
          <Box className={styles.file_box} component="div">
            <div className={styles.file_text}>
              <label>Subjects ({state.subject_body.length})</label>
            </div>
            <div className={styles.box_btn_add}>
              <Box className={styles.btn_add} onClick={handleClickOpenAdd}>
                Add
              </Box>
            </div>
          </Box>
        </div>
        <div className={styles.subjectTable}>
          <div className={styles.table_box_1}>
            {isLoading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {isLoading === true ? (
                  <Example />
                ) : (
                  <div className={styles.schoolList_Table}>
                    <div className={styles.schoolList_Table_head + ' ' + styles.subHead}>
                      {state.subject_head.map((elm, key) => (
                        <div key={key} className={styles.schoolList_Table_head_data + ' ' + styles.subHeadBody}>
                          {elm}
                        </div>
                      ))}
                    </div>
                    {
                      <div className={styles.schoolList_Table_body}>
                        {state.subject_body.length === 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 'bold',
                            }}>
                            No Data Found
                            <span role="img" aria-label="jsx-a11y/accessible-emoji">
                              😔
                            </span>
                          </div>
                        ) : (
                          state.subject_body.map((elm, key) => (
                            <div
                              key={key}
                              className={styles.schoolList_Table_body_row + ' ' + styles.subBodyRow}
                              style={{ cursor: 'pointer' }}>
                              <div className={styles.schoolList_Table_body_row_td2 + ' ' + styles.subBodyRow_td}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  {elm.board_name}
                                </div>
                              </div>
                              <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.subBodyRow_td}>
                                {elm.course_name}
                              </div>
                              <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.subBodyRow_td}>
                                {elm.subject_name}
                              </div>
                              <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.subBody_educatroList}>
                                {elm.educator_list.map((el, key) => {
                                  return el.educator_name === null ? (
                                    ''
                                  ) : (
                                    <div key={key} className={styles.educator_name}>
                                      <span> {el.educator_name}</span>
                                      <Icon className={styles.crossArrow}>
                                        <img
                                          src={require('../../../../assets/icons/CrossDelete.png')}
                                          alt="deletebox"
                                          style={{
                                            cursor: 'pointer',
                                            width: '12px',
                                            height: '12px',
                                          }}
                                          onClick={(e) =>
                                            handleEducatorDelete(e, elm.subject_id, el.study_circle_subject_educator_id)
                                          }
                                        />
                                      </Icon>
                                    </div>
                                  );
                                })}
                                {state.subId === elm.subject_id && showEdu ? (
                                  <Box
                                    component="select"
                                    className={styles.options}
                                    onChange={(e) => {
                                      addEducatorToSubject(e);
                                    }}>
                                    <option>{'Educator List'}</option>
                                    {state.drop_edu.map((el, key) => (
                                      <option value={el.educator_id + ' ' + el.educator_name} key={key}>
                                        {el.educator_name}
                                      </option>
                                    ))}
                                  </Box>
                                ) : elm.educator_list.length > 0 ? (
                                  <Icon className={styles.plus_icon}>
                                    <img
                                      src={require('../../../../assets/icons/plus_icon.png')}
                                      alt="plus_icon"
                                      style={{
                                        cursor: 'pointer',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                      onClick={(e) => handleClickIcon(e, elm.subject_id)}
                                    />
                                  </Icon>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.subBodyRow_td}>
                                <Icon
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                  }}>
                                  <img
                                    src={require('../../../../assets/icons/DeleteBox.png')}
                                    alt="deletebox"
                                    style={{
                                      cursor: 'pointer',
                                      width: '30px',
                                      height: '30px',
                                    }}
                                    onClick={(e) => handleSubjectDelete(e, elm.study_circle_subject_id)}
                                  />
                                </Icon>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* <div
          className={styles.file_container}
          style={{ margin: "-30px 0 -10px 0" }}
        >
          <Box className={styles.file_box} component="div">
            <div className={styles.file_text}>
              <label>Educator Details ({state.educator_body.length})</label>
            </div>
            <div className={styles.box_btn_add}>
              <Box className={styles.btn_add} onClick={handleClickOpenAdd}>
                Add
              </Box>
            </div>
          </Box>
        </div>
        <div className={styles.educatorTable}>
          <div className={styles.table_box_1}>
            {isLoading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {isLoading === true ? (
                  <Example />
                ) : (
                  <div className={styles.schoolList_Table}>
                    <div className={styles.schoolList_Table_head}>
                      {state.educator_head.map((elm, key) => (
                        <div
                          key={key}
                          className={styles.schoolList_Table_head_data}
                        >
                          {elm}
                        </div>
                      ))}
                    </div>
                    {
                      <div className={styles.schoolList_Table_body}>
                        {state.educator_body.length === 0
                          ? <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
                        </div>
                          : state.educator_body.map((elm, key) => (
                              <div
                                key={key}
                                className={styles.schoolList_Table_body_row}
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td2
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      src={
                                        elm.cmn_teacher_profile_pic_file_path
                                      }
                                      alt="avatar"
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginRight: "10px",
                                        marginLeft: "15px"
                                      }}
                                    />
                                    <div>{elm.cmn_teacher_name}</div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {elm.cmn_syllabus_name}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {elm.qb_class_number}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {elm.cmn_subject_name}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {"-"}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  <Icon
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      src={require("../../../../assets/icons/DeleteBox.png")}
                                      alt="deletebox"
                                      style={{ cursor: "pointer",width:"30px",height:"30px" }}
                                      data-value={
                                        elm.cmn_study_circle_teacher_subject_id
                                      }
                                      onClick={(e) => handleDelete(e)}
                                    />
                                  </Icon>
                                </div>
                              </div>
                            ))}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div> */}
        <div className={styles.file_container}>
          <Box className={styles.file_box} component="div">
            <div className={styles.file_text}>
              <div>Student List ({state.student_body.length})</div>
            </div>
            <div className={styles.file_opt}>
              <div
                style={{
                  minWidth: '250px',
                }}
                className="select_wraper">
                {boardId ? (
                  <Select
                    style={{
                      borderRadius: '8px',
                      fontSize: '12px',
                      background: 'white',
                    }}
                    placeholder="Select Acadamic Period"
                    className="select"
                    /*options={instituteData?.map((i) => ({
                    ...i,
                    board_id: boardId,
                  }))}*/
                    // options={instituteData?.filter((i) => i.is_current === true)}
                    options={optionData}
                    labelField="label"
                    valueField="value"
                    values={selectedAcademicPeriod}
                    onChange={(e) => {
                      console.log(e);
                      // setSelectedAcademicPeriod(e);
                      // window.localStorage.setItem('institutionData', JSON.stringify(e?.[0]));
                    }}
                    separator
                    searchable
                    clearable={false}
                    multi={false}
                    backspaceDelete={false}
                  />
                ) : null}
              </div>
              <Box
                component="button"
                onClick={downloadFile}
                className={styles.file_download}
                // disabled={selectedAcademicPeriod?.length !== 0}
                disabled={!boardId}
                style={{ background: `${boardId ? 'white' : 'rgb(233, 229, 226)'}`, color: `${boardId ? null : '#a9a9a9'}` }}>
                <img
                  src={require('../../../../assets/icons/down.png')}
                  alt="down"
                  style={{
                    marginRight: '10px',
                    width: '13px',
                    height: '16px',
                  }}
                />
                Download Student List
              </Box>
              <Formik>
                {(formik) => {
                  return (
                    <div>
                      <Form>
                        <Field
                          type="file"
                          id="filetoupload"
                          name="filetoupload"
                          onChange={(e) => {
                            uploadFile(e);
                            e.target.value = null;
                          }}
                          disabled={!boardId}
                          style={{ display: 'none' }}
                        />
                        <Box
                          component="div"
                          className={styles.file_upload}
                          style={{ background: `${boardId ? 'white' : 'rgb(233, 229, 226)'}` }}>
                          <label
                            href="#"
                            htmlFor="filetoupload"
                            style={{
                              borderRadius: '10px',
                              width: '220px',
                              height: '32px',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              color: `${boardId ? null : '#a9a9a9'}`,
                            }}>
                            <img
                              src={require('../../../../assets/icons/up.png')}
                              alt="up"
                              style={{
                                marginRight: '10px',
                                width: '13px',
                                height: '16px',
                              }}
                            />
                            Upload New Sutdent List
                          </label>
                        </Box>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </div>
        <div>
          <div className={styles.table_box_1}>
            {isLoading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {isLoading === true ? (
                  <Example />
                ) : (
                  <div className={styles.schoolList_Table}>
                    <div className={styles.schoolList_Table_head}>
                      {state.student_head.map((elm, key) => (
                        <div key={key} className={styles.schoolList_Table_head_data}>
                          {elm}
                        </div>
                      ))}
                    </div>
                    {
                      <div className={styles.schoolList_Table_body}>
                        {state.student_body.length === 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 'bold',
                            }}>
                            No Data Found
                            <span role="img" aria-label="jsx-a11y/accessible-emoji">
                              😔
                            </span>
                          </div>
                        ) : (
                          state.student_body.map((elm, key) => (
                            <div key={key} className={styles.schoolList_Table_body_row} style={{ cursor: 'pointer' }}>
                              <div className={styles.schoolList_Table_body_row_td2}>{elm.roll_number}</div>
                              <div className={styles.schoolList_Table_body_row_td3}>
                                <div className={styles.dds}>
                                  <div>
                                    <Avatar
                                      src={elm.profile_pic_url}
                                      alt="avatar"
                                      style={{
                                        width: '16px',
                                        height: '16px',
                                      }}
                                    />
                                  </div>
                                  <div>{elm.student_name}</div>
                                </div>
                              </div>
                              <div className={styles.schoolList_Table_body_row_td3}>{elm.inst_stud_id}</div>
                              <div className={styles.schoolList_Table_body_row_td3}>{elm.inst_defined_group}</div>
                            </div>
                          ))
                        )}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
          <Box className={styles.buttons}>
            <div className={styles.buttons_div}>
              <Box component="button" className={styles.btn_cancel}>
                Cancel
              </Box>
              <Box component="button" className={styles.btn_save} onClick={addStudyCircleTab}>
                Save
              </Box>
            </div>
          </Box>
        </div>
        <ModalComponent show={state.showHidePlus} onClick={handleClosePlus} className="addSubModal">
          <Box className={styles.main_box} component="div" style={{ marginTop: '-20px' }}>
            <Formik
              initialValues={initialFormBox || state}
              validationSchema={validationFormBox}
              onSubmit={handleSave}
              enableReinitialize>
              {(formik) => {
                return (
                  <Box component="div" style={{ width: '100%', height: 'auto' }}>
                    <Form className={styles.modal_Form}>
                      <label htmlFor="board">Board</label>
                      <Box className={styles.main_box_1} component="div">
                        <Field
                          as="select"
                          name="board"
                          id="board"
                          value={undefined}
                          className={styles.d_options}
                          onChange={(e) => changedBoard(e, 'board')}>
                          <option value="-1">{'Board'}</option>
                          {state.drop_board.map((el, key) => (
                            <option value={el.board_id} key={key}>
                              {el.display_name}
                            </option>
                          ))}
                        </Field>
                      </Box>
                      <div className={styles.hierarchy}>Filter with hierarchy</div>
                      <label htmlFor="course">Course</label>
                      <Box className={styles.main_box_2} component="div">
                        <Field
                          as="select"
                          name="course"
                          id="course"
                          value={undefined}
                          className={styles.d_options}
                          onChange={(e) => changedBoard(e, 'course')}>
                          <option value="-1">{'Course'}</option>
                          {state.drop_course.map((el, key) => (
                            <option value={el.course_id} key={key}>
                              {el.display_name}
                            </option>
                          ))}
                        </Field>
                      </Box>
                      <label htmlFor="subject">Subject</label>

                      <Box className={styles.main_box_3} component="div">
                        <Field
                          as="select"
                          name="subject"
                          id="subject"
                          value={undefined}
                          // value={editVal ? selectedBoard() : undefined}
                          // onChange={(e)=>setState({...state,subject:e.terget.value})}
                          onChange={(e) => changedBoard(e, 'subject')}
                          className={styles.d_options}>
                          <option value="-1">{'Subject'}</option>
                          {state.drop_subject.map((el, key) => (
                            <option value={el.subject_id} key={key}>
                              {el.display_name}
                            </option>
                          ))}
                        </Field>
                      </Box>
                      <label htmlFor="board">Description</label>
                      <Box className={styles.main_box_1} component="div" style={{ height: '60px' }}>
                        <Field
                          name="sub_desc"
                          // placeholder="Topic Display Name"
                          component="textarea"
                          className={styles.B_Field}
                          id="sub_desc"
                          value={state.sub_desc}
                          onChange={(e) => {
                            setState({
                              ...state,
                              sub_desc: e.target.value,
                            });
                          }}
                        />
                      </Box>
                      <Box className={styles.buttonss}>
                        <button className={styles.cancelBtn} onClick={handleClosePlus} type="reset">
                          Cancel
                        </button>
                        <button
                          className={styles.saveBtn}
                          type="submit"
                          onClick={(e) => {
                            let sub_desc_val = document.getElementById('sub_desc').value;
                            formik.setFieldValue('sub_desc', sub_desc_val);
                            setNextPage(true);
                          }}>
                          Save
                        </button>
                      </Box>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        </ModalComponent>
        <div></div>
      </div>
    );
  };

  return (
    <div>
      {nextPage === false ? (
        <Box className={styles.container}>
          <Box className={styles.top_box} component="div">
            <Box className={styles.add_box} component="div" onClick={handleClickOpen}>
              Add Study Circle
            </Box>
            {/* <Box className={styles.format} component="div">
             
                <img
                  src={require("../../../../assets/icons/down.png")}
                  alt="down"
                  style={{ marginRight: "10px", width: "13px", height: "16px" }}
                />
              
              Download StudyCircle List
            </Box>
            <Box component="div" className={styles.data}>
              <img
                src={require("../../../../assets/icons/up.png")}
                alt="up"
                style={{ marginRight: "10px", width: "13px", height: "16px" }}
              />
              Upload StudyCircle List
            </Box> */}
          </Box>
          <Box>
            <div className={styles.table_box_list}>
              {isLoading === true ? (
                <Example />
              ) : (
                <div className={styles.table_box}>
                  {isLoading === true ? (
                    <Example />
                  ) : (
                    <div className={styles.schoolList_Table}>
                      <div className={styles.schoolList_Table_head}>
                        {state.study_circle_t_head.map((elm, key) => (
                          <div key={key} className={styles.schoolList_Table_head_data}>
                            {elm}
                          </div>
                        ))}
                      </div>
                      {
                        <div className={styles.schoolList_Table_body}>
                          {state.study_circle_t_body === 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'bold',
                              }}>
                              No Data Found
                              <span role="img" aria-label="jsx-a11y/accessible-emoji">
                                😔
                              </span>
                            </div>
                          ) : (
                            state.study_circle_t_body.map((elm, key) => (
                              <div
                                className={styles.schoolList_Table_body_row}
                                key={key}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  window.localStorage.setItem('board_id', elm?.board_id);
                                  window.localStorage.setItem('academic_period_name', elm?.academic_period_name);

                                  onTableClick(
                                    elm.study_circle_id,
                                    elm.study_circle_name,
                                    elm.incharge_educator_id,
                                    elm.description,
                                  );
                                }}>
                                <div className={styles.schoolList_Table_body_row_td2}>{elm.study_circle_name}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.course_count}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.subject_count}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.student_count}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.incharge_educator_id}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.incharge_educator_name}</div>
                                <div className={styles.schoolList_Table_body_row_td3}>{elm.description}</div>
                              </div>
                            ))
                          )}
                        </div>
                      }
                    </div>
                  )}
                </div>
              )}
            </div>
          </Box>
          <ModalComponent show={state.showHide} onClick={handleClose}>
            <Box className={styles.main_box} component="div">
              <Formik
                initialValues={initialBox || state}
                validationSchema={validationBox}
                onSubmit={addStudyCircle}
                enableReinitialize>
                {(formik) => {
                  return (
                    <Box component="div" style={{ width: '100%', height: 'auto' }}>
                      <Form className={styles.modal_Form}>
                        <Box component="div" style={{ width: '68%', height: 'auto' }}>
                          <div className={styles.modal_Text}>Study Circle Name</div>
                        </Box>
                        <Box className={styles.main_box_1} component="div">
                          <Field name="study_circleName" type="text" className={styles.B_Field} id="study_circleName" />
                        </Box>
                        <Box component="div" style={{ width: '68%', height: 'auto' }}>
                          <div className={styles.modal_Text}>Incharge of Study Circle</div>
                        </Box>
                        <Box className={styles.main_box_2} component="div">
                          <Field as="select" name="incharge_studyCircle" className={styles.d_options}>
                            <option>{'Select'}</option>
                            {state.incharge_data.map((elm, key) => {
                              return (
                                <option value={elm.id} key={key}>
                                  {elm.name}
                                </option>
                              );
                            })}
                          </Field>
                        </Box>
                        <Box component="div" style={{ width: '68%', height: 'auto' }}>
                          <div className={styles.modal_Text}>Description</div>
                        </Box>
                        <Box className={styles.main_box_2} style={{ height: '60px' }} component="div">
                          <Field
                            name="desc"
                            // placeholder="Topic Display Name"
                            component="textarea"
                            className={styles.B_Field}
                            id="desc"
                            value={state.desc}
                            onChange={(e) => {
                              setState({
                                ...state,
                                desc: e.target.value,
                              });
                            }}
                          />
                        </Box>
                        <Box className={styles.buttonss}>
                          <button className={styles.cancelBtn} onClick={handleClose} type="reset">
                            Cancel
                          </button>
                          <button
                            className={styles.saveBtn}
                            type="submit"
                            disabled={!formik.isValid || formik.isSubmitting}
                            onClick={(e) => {
                              setState({ ...state, showHide: false });
                              let desc_val = document.getElementById('desc').value;
                              formik.setFieldValue('desc', desc_val);
                            }}>
                            Save
                          </button>
                        </Box>
                      </Form>
                    </Box>
                  );
                }}
              </Formik>
            </Box>
          </ModalComponent>
        </Box>
      ) : (
        NewScreen()
      )}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default StudyCircle;
