/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styles from './Images.module.css';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
import { getAllSubjectsByBoardId } from '../../../../api/InstitutionSubjects';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';
import { courseImages, subjectImages, topicImages, boardImages, uploadImageFile } from '../../../../api/images';
import ReactLoading from 'react-loading';
import default_placeholder from '../../../../assets/images/default_placeholder.png';
import { ToastContainer, toast } from 'react-toastify';
import { Dialogbox } from '../../../../components';
const SelectOptions = ({ options, label, placeHolder, handleChange, value }) => {
  return (
    <div className={styles.containers}>
      <label htmlFor="cars" style={{ width: 'auto', display: 'block' }}>
        {label}
      </label>

      <select className={styles.selectMain} onChange={handleChange} value={value} id="cars">
        <option value="-1">Select {label}</option>
        {options?.map((i, index) => (
          <option key={index} value={i.id}>
            {i.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const Images = () => {
  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 8);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  const [errors, setErrors] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [optionsList, setOptionsList] = useState({
    board: [],
    course: [],
    subject: [],
  });
  const [selectedValues, setSelectedValues] = useState({
    board_id: '-1',
    course_id: '-1',
    subject_id: '-1',
  });
  const [selectedFolderName, setSelectedFolderName] = useState('board-images');
  const [tableData, setTableData] = useState([]);
  const [boardupdate, setBoardupdate] = useState(false);
  const getBoardList = async () => {
    const getBoard = await getBoardsByNameInInstitution(school_id);
    setOptionsList((prevOptions) => ({ ...prevOptions, board: getBoard.data.data }));
    setBoardupdate(!boardupdate);
  };
  const getBoardImages = async () => {
    try {
      const res = await boardImages();
      setTableData(res?.data?.data);
    } catch (err) {
      if (err?.response && err?.response.data) {
        console.log(err?.response?.data.message, 'error');
        //   toast.error(err.response.data.message);
      }
    }
  };
  useEffect(() => {
    getBoardList();
    getBoardImages();
  }, []);

  const handleDropdown = async (e, selectedvalue) => {
    setTableData([]);
    const value = Number(e?.target?.value || e);
    setIsLoading(true);
    if (selectedvalue === 'board') {
      if (value > 0) {
        setSelectedValues((pre) => ({
          ...pre,
          board_id: value,
          course_id: -1,
          subject_id: -1,
        }));
        const filteredBoard = optionsList?.board?.filter((i) => i.board_id === Number(value));
        setSelectedFolderName(`${filteredBoard?.[0]?.board_id}-${filteredBoard?.[0]?.board_code}`);
        try {
          // PORT-IC-4: Retrieve list of courses of an institution
          const getCourse = await getCoursesListOfAnInstitution(school_id, value, Auth);

          setOptionsList((prevState) => ({
            ...prevState,
            course: getCourse?.data?.data,
          }));
        } catch (err) {
          if (err?.response && err?.response?.data) {
            console.log(err?.response?.data.message, 'error');
            //   toast.error(err.response.data.message);
          }
        }
        try {
          const res = await courseImages(value);
          setTableData(res?.data?.data);
          setIsLoading(false);
        } catch (error) {
          console.log(error?.response.data.message, 'error');
          setIsLoading(false);
        }
      } else {
        getBoardImages();
        setSelectedFolderName('board-images');
        setOptionsList((prevState) => ({
          ...prevState,
          course: [],
          subject: [],
        }));
        setSelectedValues((pre) => ({
          ...pre,
          board_id: -1,
          course_id: -1,
          subject_id: -1,
        }));
      }
      setIsLoading(false);
    } else if (selectedvalue === 'course') {
      if (value > 0) {
        setSelectedValues((pre) => ({
          ...pre,
          course_id: value,
          subject_id: -1,
        }));

        try {
          const getSubject = await getAllSubjectsByBoardId(school_id, selectedValues.board_id, value, '');

          setOptionsList((prevState) => ({
            ...prevState,
            subject: getSubject?.data?.data,
          }));
        } catch (err) {
          if (err?.response && err?.response.data) {
            console.log(err?.response?.data.message, 'error');
            //   toast.error(err.response.data.message);
          }
        }
        try {
          const courseRes = await courseImages(selectedValues?.board_id);
          const res = await subjectImages(selectedValues?.board_id, value);
          setTableData(res?.data?.data);
          setIsLoading(false);
          const filteredCourse = courseRes?.data?.data?.filter((i) => i?.course_id === Number(value));
          setSelectedFolderName(`${filteredCourse?.[0]?.course_id}-${filteredCourse?.[0]?.name_part}`);
        } catch (error) {
          console.log(error?.response?.data?.message, 'error');
          setIsLoading(false);
        }
      } else {
        const filteredBoard = optionsList?.board?.filter((i) => i.board_id === Number(selectedValues?.board_id));
        setSelectedFolderName(`${filteredBoard?.[0]?.board_id}-${filteredBoard?.[0]?.board_code}`);
        const res = await courseImages(selectedValues?.board_id);
        setTableData(res?.data?.data);
        setIsLoading(false);

        setOptionsList((prevState) => ({
          ...prevState,
          subject: [],
        }));
        setSelectedValues((pre) => ({
          ...pre,

          course_id: -1,
          subject_id: -1,
        }));
      }
    } else if (selectedvalue === 'subject') {
      setIsLoading(true);
      setSelectedValues((pre) => ({
        ...pre,
        subject_id: value,
      }));
      // pageNum = 1;
      // default_page = 1;
      // setTreeDisplay(true);
      if (value > 0) {
        try {
          const res = await topicImages(selectedValues?.board_id, selectedValues?.course_id, value);
          const subjectRes = await subjectImages(selectedValues?.board_id, selectedValues?.course_id);
          setTableData(res?.data?.data);

          const filteredCourse = subjectRes?.data?.data?.filter((i) => i?.subject_id === Number(value));
          setSelectedFolderName(`${filteredCourse?.[0]?.subject_id}-${filteredCourse?.[0]?.name_part}`);
          setIsLoading(false);
        } catch (err) {
          if (err?.response && err?.response.data) {
            console.log(err?.response?.data.message, 'error');
            //toast.error(err.response.data.message);
          }

          // setNoData(true);
          setIsLoading(false);
          // setTreeDisplay(false);
        }
      } else {
        try {
          const courseRes = await courseImages(selectedValues?.board_id);
          const res = await subjectImages(selectedValues?.board_id, selectedValues?.course_id);
          setTableData(res?.data?.data);
          const filteredCourse = courseRes?.data?.data?.filter((i) => i?.course_id === Number(selectedValues?.course_id));
          setSelectedFolderName(`${filteredCourse?.[0]?.course_id}-${filteredCourse?.[0]?.name_part}`);
        } catch (error) {}

        setIsLoading(false);

        setSelectedValues((pre) => ({
          ...pre,
          subject_id: -1,
        }));
      }
    }
  };
  useEffect(() => {
    const apicall = async () => {
      if (localStorage.getItem('imageDropdown') && optionsList?.board?.length > 0) {
        const storeValue = JSON.parse(localStorage.getItem('imageDropdown'));
        console.log(selectedValues);
        selectedValues.board_id = storeValue?.board_id;
        selectedValues.course_id = storeValue?.course_id;
        selectedValues.subject_id = storeValue?.subject_id;

        if (+storeValue?.board_id) {
          const getCourse = await getCoursesListOfAnInstitution(school_id, storeValue?.board_id);

          setOptionsList((prevState) => ({
            ...prevState,
            course: getCourse?.data?.data,
          }));
        }
        if (+storeValue?.course_id) {
          try {
            const getSubject = await getAllSubjectsByBoardId(school_id, storeValue.board_id, storeValue?.course_id);

            setOptionsList((prevState) => ({
              ...prevState,
              subject: getSubject?.data?.data,
            }));
          } catch (err) {
            if (err?.response && err?.response.data) {
              console.log(err?.response?.data.message, 'error');
            }
          }
        }
        recallApi();
      }
    };
    apicall();
  }, [optionsList?.board]);
  useEffect(() => {
    if (optionsList?.board.length) {
      window.localStorage.setItem('imageDropdown', JSON.stringify(selectedValues));
    }
  }, [selectedValues]);

  const ErrModal = (errMsg = []) => {
    return (
      <Dialogbox
        Dialogbox
        open={popUp}
        handleClose={() => {
          setPopUp(false);
        }}>
        <div className={styles.errMod}>
          <div className="Msg">
            <div className="errMsgErrModal">{`Errors found in upload`}</div>
            <div
              className="closeBtnErrModal"
              onClick={() => {
                setPopUp(false);
              }}>
              <img
                src={require('../../../../assets/icons/close.png')}
                style={{ width: '20px', height: '20px' }}
                alt="closeBtn"
                className={styles.subHrErrorModalImg}
              />
            </div>
          </div>
          <table className="content_table">
            <thead>
              <tr>
                <th>Errors</th>
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>
                      {key + 1}
                      {') '}
                      {elm}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  const recallApi = async () => {
    try {
      if (+selectedValues?.board_id > 0 && +selectedValues?.course_id < 0 && +selectedValues?.subject_id < 0) {
        console.log('frvkfksdjbvskj');
        await handleDropdown(selectedValues?.board_id, 'board');
      } else if (+selectedValues?.board_id > 0 && +selectedValues?.course_id > 0 && +selectedValues?.subject_id < 0) {
        console.log('frvkfksdjbvskj');
        await handleDropdown(selectedValues?.course_id, 'course');
      } else if (+selectedValues?.board_id > 0 && +selectedValues?.course_id > 0 && +selectedValues?.subject_id > 0) {
        console.log('frvkfksdjbvskj');
        await handleDropdown(selectedValues?.subject_id, 'subject');
      } else {
        getBoardImages();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImages = async (e) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setIsLoading(true);

    formdata.append('filetoupload', file);

    // PORT-SH-3:  Import the Subject Hierarchy Excel Template
    try {
      await uploadImageFile(selectedValues?.board_id, selectedValues?.course_id, selectedValues?.subject_id, formdata);
      setIsLoading(false);
      await recallApi();

      toast.success('File Uploaded Successfully');
    } catch (err) {
      console.log(err?.response);
      if (err?.response?.data?.errors.length) {
        setPopUp(true);
      }
      setErrors(err?.response?.data?.errors);

      setIsLoading(false);
    }
  };

  return (
    <div>
      {popUp ? ErrModal(errors) : null}
      {isLoading ? <ReactLoading type="bars" className="loadingAni" color="#DCEEDC" /> : null}
      <div
        className="containers"
        style={{ display: 'flex', alignItems: 'center', padding: '10px 15px', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <SelectOptions
            options={optionsList.board?.map((i) => ({ name: i.display_name, id: i.board_id, instId: i.institution_board_id }))}
            label={'Board'}
            placeHolder=""
            handleChange={(e) => {
              handleDropdown(e, 'board');
            }}
            value={selectedValues?.board_id}
          />
          <SelectOptions
            options={optionsList.course?.map((i) => ({ name: i.display_name, id: i.course_id, instId: i.institution_board_id }))}
            label={'Course'}
            placeHolder=""
            handleChange={(e) => handleDropdown(e, 'course')}
            value={selectedValues?.course_id}
          />
          <SelectOptions
            options={optionsList.subject?.map((i) => ({ name: i.display_name, id: i.subject_id }))}
            label={'Subject'}
            placeHolder=""
            handleChange={(e) => handleDropdown(e, 'subject')}
            value={selectedValues?.subject_id}
          />
        </div>
        <div className={styles.upload}>
          <input
            type="file"
            id="imagesupload"
            name="imagesupload"
            // multiple
            accept=".zip"
            onChange={(e) => {
              uploadImages(e);
              e.target.value = null;
            }}
            style={{ display: 'none', marginRight: '5px' }}
          />
          <label
            htmlFor="imagesupload"
            style={{
              borderRadius: '10px',
              width: '165px',
              height: '32px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '10px',
              background: 'white',
              border: '1px solid #dddddd ',
            }}>
            <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
            Upload Images
          </label>
        </div>
      </div>
      <div style={{ paddingLeft: '15px', fontSize: '12px', letterSpacing: '1px', marginBottom: '10px' }}>
        Folder Name: {`${selectedFolderName}`}
        {/* {'board_id-board_code'} */}
      </div>
      <div style={{ padding: ' 0px 10px 10px 10px', position: 'relative', overflow: 'auto', height: '450px' }}>
        <table style={{ borderCollapse: 'collapse' }}>
          <thead style={{ position: 'sticky', top: 0 }}>
            <tr>
              <th style={{ padding: '5px 10px', background: 'white', fontSize: '14px', letterSpacing: '1px', width: '50%' }}>
                Name
              </th>
              <th style={{ padding: '5px 10px', background: 'white', fontSize: '14px', letterSpacing: '1px' }}>Image</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((i, index) => (
              <tr key={index}>
                <td>
                  {`${i?.board_id || i?.course_id || i?.subject_id || i?.topic_id}-${i?.name_part}`}
                  <div>{i?.display_name_eng && i?.display_name_eng !== '' ? i?.display_name : null}</div>
                  {/* {i?.display_name_eng && i?.display_name_eng !== ''
                    ? `${i?.course_id || i?.subject_id || i?.topic_id}-${i?.display_name_eng}(${i?.display_name})`
                    : `${i?.course_id || i?.subject_id || i?.topic_id}-${i?.display_name}`} */}
                </td>
                <td>
                  <div style={{ width: '100%' }}>
                    <img
                      src={i?.image_url || default_placeholder}
                      alt="images"
                      onError={(e) => {
                        e.target.src = default_placeholder;
                      }}
                      style={{ width: '100px', height: '100px', border: '1px solid gray' }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Images;
