import https from './init/https';

//PORT-IMG-01: Get course images

export const courseImages = async (board_id) => {
  const response = await https.get(`/images/courses?board_id=${board_id}`);

  return response;
};

//PORT-IMG-02: Get subject images
export const subjectImages = async (board_id, course_id) => {
  const response = await https.get(`/images/subjects?board_id=${board_id}&course_id=${course_id}`);

  return response;
};

// PORT-IMG-03: Get topic images
export const topicImages = async (board_id, course_id, subject_id) => {
  const response = await https.get(`/images/topics?board_id=${board_id}&course_id=${course_id}&subject_id=${subject_id}`);

  return response;
};

// PORT-IMG-04: Get board images
export const boardImages = async () => {
  const response = await https.get(`/images/boards`);

  return response;
};

// PORT-CATIMG-01: Upload category images
export const uploadImageFile = async (board_id, course_id, subject_id, data) => {
  let link = '';
  const params = [];
  if (board_id && board_id > 0) {
    params.push(`board_id=${board_id}`);
  }
  if (course_id && course_id > 0) {
    params.push(`course_id=${course_id}`);
  }
  if (subject_id && subject_id > 0) {
    params.push(`subject_id=${subject_id}`);
  }
  if (params.length > 0) {
    link = `?${params.join('&')}`;
  }

  const response = await https.put(`/images/save${link}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*',
      type: 'formData',
      'Access-Control-Allow-Headers': '*',
    },
  });
  return response;
};
