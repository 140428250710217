import { drawerWidth, transition, container } from '../defaulyStyle';

const adminStyle = (theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
  },
  mainPanel: {
    overflow: 'hidden',
    width: `calc(100% - ${drawerWidth}px)`,
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    overflowScrolling: 'smooth',
    overflowY: 'scroll',
    //   '&:hover': {
    //     overflowX: 'hidden',
    //     overflowY: 'scroll',
    //   },
  },
  content: {
    // marginTop: '10px',
    padding: '30px 15px',
    minHeight: 'calc(100vh - 123px)',
  },
  container,
  map: {
    marginTop: '70px',
  },
});

export default adminStyle;
