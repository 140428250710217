/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Icon, Box } from '@material-ui/core';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import TextError from '../../../../variables/errors/Text_error';
import add_emb from '../../../../assets/images/add_emblem.PNG';
import addImg from '../../../../assets/images/add.PNG';
import styles from './Institution.module.css';
import { ModalComponent } from '../../../../components/index';
import s3 from '../../../../config/DigitalOcean';
import configuration from '../../../../config/config';
import ButtonComponent from '../../../../components/button/Button';
import { getBoards } from '../../../../api/boards';
import { getInstitutionInfo, updateInstitutionBasicInfo } from '../../../../api/institutionBasicInfo';
import { addBoardToInstitution } from '../../../../api/institutionBoards';
import { getBoardsOfInstitution } from '../../../../api/institutionBoards';
import { updateBoardInfoInInstitution, deleteBoardFromInstitution } from '../../../../api/institutionBoards';
import { getCountries } from '../../../../api/country';

const initialValues = {
  institute_name: '',
  display_info: '',
  emblem: [],
  images: [],
};
const initialBox = {
  board_name: '',
  board: '',
  // medium: "",
  hierarchy: '',
  country: '',
  desc: '',
};

var emb_file_path;
var imgpath1;
var imgpath2;
var imgpath3;
var imgpath4;
var imgpath5;

const validationSchema = Yup.object({
  add_emblem: Yup.string(),
  institute_name: Yup.string(),
  display_info: Yup.string(),
});

const validationBox = Yup.object({
  board_name: Yup.string(),
  medium: Yup.string(),
  board: Yup.string(),
});

var t_head_admin = ['Board', 'Stucle Board (code)', 'Hierarchy', 'Courses', 'Description', ''];

var t_head = ['Board', 'Stucle Board (code)', 'Hierarchy', 'Courses', 'Description'];

const Institution = () => {
  const [editVal, setEditVal] = useState(false);
  const [state, setState] = useState({
    institute_name: '',
    instituionShortName: '',
    instituionDisplayCode: '',
    display_info: '',
    address: '',
    place: '',
    pincode: '',
    country_id: '',
    latitude: '',
    longitude: '',
    emblem: '',
    images: [],
    apiData: [],
    optBD: [],
    optMed: [],
    T_data: [],
    t_body: [],
    form_values: [],
    showHide: false,
    value: 'any value',
    cmn_syllabus_name: '',
    board: '',
    cmn_school_syllabus_id: '',
    institution_board_id: '',
    display_name: '',
    hierarchy_select_val: '',
    hierarchy_concat: '',
    course_count: '',
    description: '',
    file: '',
    imagePreview: add_emb,
    imgPrev1: addImg,
    imgPrev2: addImg,
    imgPrev3: addImg,
    imgPrev4: addImg,
    imgPrev5: addImg,
  });
  const [countryList, setCountryList] = useState([]);

  const school_id = window.localStorage.getItem('school_id');
  const token = window.localStorage.getItem('token');
  const role = window.localStorage.getItem('role');
  const stateName = window.localStorage.getItem('stateName');
  window.localStorage.setItem('tabIndex', 0);
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        // PORT-IBI-1: Retrieve institution basic info
        const T_data = await getInstitutionInfo(school_id, Auth);
        // await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/basic-info`,
        //   Auth
        // );
        // PORT-IB-2: Retrieve detailed info of boards of an institution
        const t_body = await getBoardsOfInstitution(school_id, Auth);
        // const t_body = await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/boards/detailed-info`,
        //   Auth
        // );

        // PORT-BD-1: Retrieve list of all boards

        const optBD = await getBoards(Auth);
        // const optBD = await Axios.get(`${BASE_URL}/boards`, Auth);

        // PORT-CN-1: Retrieve list of all countries

        const getCountry = await getCountries();
        // await Axios.get(`${BASE_URL}/countries/`, Auth);
        // const optMed = await (await Axios.get(`${BASE_URL}/medium`, Auth)).data;

        emb_file_path = T_data.data.data[0].emblem_file_path;
        imgpath1 = T_data.data.data[0].image_1_file_path;
        imgpath2 = T_data.data.data[0].image_2_file_path;
        imgpath3 = T_data.data.data[0].image_3_file_path;
        imgpath4 = T_data.data.data[0].image_4_file_path;
        imgpath5 = T_data.data.data[0].image_5_file_path;
        // console.log(T_data.data);

        setState((prevState) => ({
          ...prevState,
          t_body: t_body.data.data,
          optBD: optBD.data.data,
          // optMed: optMed.data,
          T_data: T_data.data.data,
          institute_name: T_data.data.data[0].inst_name,
          instituionShortName: T_data.data.data[0].inst_short_name,
          instituionDisplayCode: T_data.data.data[0].inst_display_code,
          address: T_data.data.data[0].address,
          place: T_data.data.data[0].place,
          pincode: T_data.data.data[0].pincode,
          country_id: T_data.data.data[0].country_id,
          longitude: T_data.data.data[0].longitude,
          latitude: T_data.data.data[0].latitude,
          imagePreview: T_data.data.data[0].emblem_file_path !== null ? T_data.data.data[0].emblem_file_path : add_emb,
          imgPrev1: T_data.data.data[0].image_1_file_path !== null ? T_data.data.data[0].image_1_file_path : addImg,
          imgPrev2: T_data.data.data[0].image_2_file_path !== null ? T_data.data.data[0].image_2_file_path : addImg,
          imgPrev3: T_data.data.data[0].image_3_file_path !== null ? T_data.data.data[0].image_3_file_path : addImg,
          imgPrev4: T_data.data.data[0].image_4_file_path !== null ? T_data.data.data[0].image_4_file_path : addImg,
          imgPrev5: T_data.data.data[0].image_5_file_path !== null ? T_data.data.data[0].image_5_file_path : addImg,
          display_info: T_data.data.data[0].inst_desc,
        }));

        setCountryList(getCountry.data.data);
        window.localStorage.setItem('logo', emb_file_path);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      }
    };
    fetchAPI();
    // eslint-disable-next-line
  }, []);

  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = (e) => {
    setState({ ...state, showHide: true });
    setEditVal(false);
  };
  const selectedBoard = (e) => {
    let index = document.getElementById('board').options.selectedIndex;
    state.optBD.map((el, key) => {
      if (key === index - 1) {
        return setState({
          ...state,
          hierarchy_select_val: el.hierarchy_concat,
        });
      } else if (index === 0) {
        return setState({
          ...state,
          hierarchy_select_val: '',
        });
      }
    });
  };
  // console.log(state.hierarchy_select_val)

  // const selectedMedium = () => {
  //   let o = state.optMed.findIndex((e) => e.qb_language_name === state.medium);
  //   return o === -1 ? 0 : state.optMed[o].qb_language_id;
  // };

  const handleSave = async (values, submitProps) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    // console.log(values)
    if (editVal) {
      const payload = {
        display_name: values.board_name,
        description: values.desc,
      };
      try {
        const res = await updateBoardInfoInInstitution(school_id, state?.institution_board_id, payload, Auth);
        toast.success('data edited Successfully!');
        if (res.data.status === true) {
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      }
    } else {
      // PORT-IB-1: Add a board to an institution
      const payload = {
        board_id: values.board,
        display_name: values.board_name,
        description: values.desc,
      };
      try {
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      }
      const res = await addBoardToInstitution(school_id, payload, Auth);

      toast.success('new board created successfully');
      if (res.data.status) {
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      }
    }
  };

  const handleEdit = (institution_board_id, display_name, code, hierarchy_concat, course_count, description) => {
    setEditVal(true);
    setState({
      ...state,
      institution_board_id: institution_board_id,
      display_name: display_name,
      board: code,
      hierarchy_concat: hierarchy_concat,
      course_count: course_count,
      description: description,
      showHide: true,
    });
    // selectedBoard();
    // selectedMedium();
  };

  const handleDelete = async (e) => {
    try {
      // PORT-IB-4: Delete a board of an institution
      const res = await deleteBoardFromInstitution(school_id, state.institution_board_id, Auth);
      if (res.data.status === true) {
        toast.error(`${res.statusText},data deleted successfully`);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
    // Axios.delete(
    //   `${BASE_URL}/institutions/${school_id}/boards/${state.institution_board_id}`,
    //   Auth
    // );
  };

  var FilePath;

  const handleChange = (e, imgPath) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (imgPath === 'add_emblem') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imagePreview: reader.result,
        });
      };
    } else if (imgPath === 'imagepath1') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev1: reader.result,
        });
      };
    } else if (imgPath === 'imagepath2') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev2: reader.result,
        });
      };
    } else if (imgPath === 'imagepath3') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev3: reader.result,
        });
      };
    } else if (imgPath === 'imagepath4') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev4: reader.result,
        });
      };
    } else if (imgPath === 'imagepath5') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev5: reader.result,
        });
      };
    }
    reader.readAsDataURL(file);
    if (e.target.files && e.target.files[0]) {
      const blob = e.target.files[0];
      let fileType = e.target.files[0].type;
      let ext = fileType.substr(fileType.lastIndexOf('/') + 1);

      if (imgPath === 'add_emblem') {
        FilePath = configuration.folderName + '/emblem' + school_id + '.' + ext;
        emb_file_path = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath1') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image1_' + school_id + '.' + ext;
        imgpath1 = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath2') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image2_' + school_id + '.' + ext;
        imgpath2 = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath3') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image3_' + school_id + '.' + ext;
        imgpath3 = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath4') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image4_' + school_id + '.' + ext;
        imgpath4 = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath5') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image5_' + school_id + '.' + ext;
        imgpath5 = configuration.digitalOceanSpaces + FilePath;
      }
      console.log(FilePath);
      console.log(imgpath5);
      const params = {
        Body: blob,
        Bucket: `${configuration.bucketName}`,
        Key: FilePath,
      };
      s3.putObject(params)
        .on('build', (request) => {
          request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
          request.httpRequest.headers['Content-Length'] = blob.size;
          request.httpRequest.headers['Content-Type'] = blob.type;
          request.httpRequest.headers['x-amz-acl'] = 'public-read';
          request.httpRequest.headers['Access-Control-Allow-Origin'] = '*';
        })
        .send((err) => {
          if (err) console.log(err, 'Img error');
          else {
            const imageUrl = `${configuration.digitalOceanSpaces}` + blob.name;
            console.log(imageUrl);
          }
        });
    }
  };

  const handleFormSave = async () => {
    // PORT-IBI-2: Update institution basic info
    let dataToUpdate = {
      name: state.institute_name,
      description: state.display_info,
      short_name: state.instituionShortName,
      emblem_file_path: emb_file_path,
      image_1_file_path: imgpath1,
      image_2_file_path: imgpath2,
      image_3_file_path: imgpath3,
      image_4_file_path: imgpath4,
      image_5_file_path: imgpath5,
      code: state.instituionDisplayCode,
      address: state.address,
      place: state.place,
      pincode: state.pincode,
      country_id: state.country_id,
      latitude: state.latitude,
      longitude: state.longitude,
      // id: school_id,
    };
    // Axios.put(
    //   `${BASE_URL}/institutions/${school_id}/basic-info`,
    //   {
    //     name: state.institute_name,
    //     description: state.display_info,
    //     short_name: state.instituionShortName,
    //     emblem_file_path: emb_file_path,
    //     image_1_file_path: imgpath1,
    //     image_2_file_path: imgpath2,
    //     image_3_file_path: imgpath3,
    //     image_4_file_path: imgpath4,
    //     image_5_file_path: imgpath5,
    //     code: state.instituionDisplayCode,
    //     address: state.address,
    //     place: state.place,
    //     pincode: state.pincode,
    //     country_id: state.country_id,
    //     latitude: state.latitude,
    //     longitude: state.longitude,
    //     // id: school_id,
    //   },
    //   Auth
    // )

    try {
      const res = await updateInstitutionBasicInfo(dataToUpdate, school_id);
      toast.success('data edited Successfully!');
      if (res.data.status === true) {
        setState({
          ...state,
          imagePreview: emb_file_path,
          imgPrev1: imgpath1,
          imgPrev2: imgpath2,
          imgPrev3: imgpath3,
          imgPrev4: imgpath4,
          imgPrev5: imgpath5,
        });
        window.localStorage.setItem('school_logo', state.imagePreview);
        window.localStorage.setItem('school_address', state.display_info);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  return (
    <Box component="div" className={styles.main_box}>
      <Box className={styles.top_box}>
        <div className={styles.top_box_body}>
          <div className={styles.top_box_format}>
            <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
            Download Institute
          </div>
          <div className={styles.top_box_data}>
            <img src={require('../../../../assets/icons/up.png')} alt="down" style={{ paddingRight: '10px' }} />
            Upload Institute
          </div>
        </div>
      </Box>
      <Box className={styles.form_box}>
        <Formik initialValues={state || initialValues} validationSchema={validationSchema} enableReinitialize>
          {(formik) => {
            return (
              <Box className={styles.form_container} component="div">
                <Form className={styles.form_box}>
                  <Box className={styles.form_box_fields} component="div">
                    <Box component="div" className={styles.form_box_fields_l} id="get_embed">
                      <Field
                        type="file"
                        id="add_emblem"
                        name="add_emblem"
                        className={styles.add_Emblem}
                        onChange={(e) => handleChange(e, 'add_emblem')}
                        accept="image/x-png,image/jpeg,image/jpg"
                        multiple
                      />

                      <img
                        htmlFor="add_emblem"
                        src={state.imagePreview !== '' ? state.imagePreview : add_emb}
                        alt=""
                        style={{
                          width: '195px',
                          height: '195px',
                          borderRadius: '6px',
                          border: '1px solid #dad9d9',
                        }}
                      />

                      <ErrorMessage name="add_emblem" component={TextError}>
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                    </Box>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="institute_name" className={styles.labels}>
                        Institute Name
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        <Field
                          type="text"
                          id="institute_name"
                          name="institute_name"
                          className={styles.institute_name_f}
                          value={state.institute_name}
                          onChange={(e) =>
                            setState({
                              ...state,
                              institute_name: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <div className={styles.shortContainer}>
                        <div>
                          <label htmlFor="InstituionShortName" className={styles.labels}>
                            Instituion Short Name
                          </label>
                          <div className={styles.displayInfoshort}>
                            <Field
                              type="text"
                              id="InstituionShortName"
                              name="InstituionShortName"
                              value={state.instituionShortName}
                              className={styles.display_info_f}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  instituionShortName: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="InstituionDisplayCode" className={styles.labels}>
                            Instituion Display Code
                          </label>
                          <div className={styles.displayInfoshort}>
                            <Field
                              type="text"
                              id="InstituionDisplayCode"
                              name="InstituionDisplayCode"
                              value={state.instituionDisplayCode}
                              className={styles.display_info_f}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  instituionDisplayCode: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <label htmlFor="display_info" className={styles.labels}>
                        Display Info
                      </label>
                      <Box component="div" className={styles.display_info}>
                        <Field
                          type="text"
                          id="display_info"
                          name="display_info"
                          value={state.display_info}
                          className={styles.display_info_f}
                          onChange={(e) => setState({ ...state, display_info: e.target.value })}
                        />
                      </Box>
                      <label htmlFor="images" className={styles.labels}>
                        {stateName ? 'Partners' : 'Images'}
                      </label>
                      <Box component="div" className={styles.images}>
                        <Box className={styles.imgs1} component="div">
                          <Field
                            type="file"
                            id="imagepath1"
                            name="imagepath1"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, 'imagepath1')}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev1 !== '' ? state.imgPrev1 : addImg}
                            alt=""
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '6px',
                              border: '1px solid #dad9d9',
                            }}
                          />
                        </Box>
                        <Box className={styles.imgs2} component="div">
                          <Field
                            type="file"
                            id="imagepath2"
                            name="imagepath2"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, 'imagepath2')}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev2 !== '' ? state.imgPrev2 : addImg}
                            alt=""
                            className={styles.imags}
                          />
                        </Box>
                        <Box className={styles.imgs3} component="div">
                          <Field
                            type="file"
                            id="imagepath3"
                            name="imagepath3"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, 'imagepath3')}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev3 !== '' ? state.imgPrev3 : addImg}
                            alt=""
                            className={styles.imags}
                          />
                        </Box>
                        <Box className={styles.imgs4} component="div">
                          <Field
                            type="file"
                            id="imagepath4"
                            name="imagepath4"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, 'imagepath4')}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev4 !== '' ? state.imgPrev4 : addImg}
                            alt=""
                            className={styles.imags}
                          />
                        </Box>
                        <Box className={styles.imgs5} component="div">
                          <Field
                            type="file"
                            id="imagepath5"
                            name="imagepath5"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, 'imagepath5')}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev5 !== '' ? state.imgPrev5 : addImg}
                            alt=""
                            className={styles.imags}
                          />
                        </Box>
                      </Box>
                      <label htmlFor="Address" className={styles.labels}>
                        Address
                      </label>
                      <Box component="div" className={styles.display_info}>
                        <Field
                          type="text"
                          id="Address"
                          name="Address"
                          value={state.address}
                          className={styles.display_info_f}
                          onChange={(e) => setState({ ...state, address: e.target.value })}
                        />
                      </Box>
                      <div className={styles.placeContainer}>
                        <div>
                          <label htmlFor="Place" className={styles.labels}>
                            Place
                          </label>
                          <div className={styles.display_info_new}>
                            <Field
                              type="text"
                              id="Place"
                              name="Place"
                              value={state.place}
                              className={styles.display_info_f}
                              onChange={(e) => setState({ ...state, place: e.target.value })}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Pincode" className={styles.labels}>
                            Pincode
                          </label>
                          <div className={styles.display_info_new}>
                            <Field
                              type="text"
                              id="Pincode"
                              name="Pincode"
                              value={state.pincode}
                              className={styles.display_info_f}
                              onChange={(e) => setState({ ...state, pincode: e.target.value })}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Country" className={styles.labels}>
                            Country
                          </label>
                          <div className={styles.display_info_new}>
                            <Box
                              component="select"
                              id="Country"
                              name="country"
                              value={state.country_id}
                              className={styles.options}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  country_id: e.target.value,
                                });
                              }}
                            >
                              <option value="-1">{'Select'}</option>
                              {countryList.map((options, key) => (
                                <option value={options.country_id} key={key}>
                                  {options.country_name}
                                </option>
                              ))}
                            </Box>
                          </div>
                        </div>
                      </div>
                      <div className={styles.placeContainer}>
                        <div>
                          <label htmlFor="Latitude" className={styles.labels}>
                            Latitude
                          </label>
                          <div className={styles.display_info_new}>
                            <Field
                              type="text"
                              id="Latitude"
                              name="Latitude"
                              value={state.latitude}
                              className={styles.display_info_f}
                              onChange={(e) => setState({ ...state, latitude: e.target.value })}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Longitude" className={styles.labels}>
                            Longitude
                          </label>
                          <div className={styles.display_info_new}>
                            <Field
                              type="text"
                              id="Longitude"
                              name="Longitude"
                              value={state.longitude}
                              className={styles.display_info_f}
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  longitude: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>

                  <Box component="div" className={styles.table_f}>
                    <div className={styles.schoolList_Table}>
                      <div className={styles.schoolList_Table_head}>
                        {role === 'StucleAdmin'
                          ? t_head_admin.map((elm, key) => (
                              <div key={key} className={styles.schoolList_Table_head_data}>
                                {elm}
                              </div>
                            ))
                          : t_head.map((elm, key) => (
                              <div key={key} className={styles.schoolList_Table_head_data}>
                                {elm}
                              </div>
                            ))}
                        {/* {role === "StucleAdmin" ? (
                          <div className={styles.schoolList_Table_head_data}>
                            Edit
                          </div>
                        ) : null} */}
                      </div>
                      <div className={styles.schoolList_Table_body}>
                        {state.t_body.map((elm, key) => (
                          <div key={key} className={styles.schoolList_Table_body_row}>
                            <div className={styles.schoolList_Table_body_row_td2}>{elm.display_name}</div>
                            <div className={styles.schoolList_Table_body_row_td3}>{elm.code}</div>
                            <div className={styles.schoolList_Table_body_row_td3}>{elm.hierarchy_concat}</div>
                            <div className={styles.schoolList_Table_body_row_td3}>{elm.course_count}</div>
                            <div className={styles.schoolList_Table_body_row_td3}>{elm.description}</div>
                            {role === 'StucleAdmin' ? (
                              <div
                                className={styles.schoolList_Table_body_row_td3}
                                // onClick={() =>
                                //   handleEdit(
                                //     elm.institution_board_id,
                                //     elm.display_name,
                                //     elm.code,
                                //     elm.hierarchy_concat,
                                //     elm.course_count,
                                //     elm.description,
                                //   )
                                // }
                              >
                                {/* <Icon>
                                  <img
                                    src={require('../../../../assets/icons/edit.png')}
                                    alt="edit"
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Icon> */}
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Box>
                  {/* <Box className={styles.btn_add} onClick={() => handleClickOpen('add')}>
                    Add
                  </Box> */}
                </Form>
                <Box className={styles.buttons}>
                  <div className={styles.buttons_div}>
                    <ButtonComponent className={styles.btn_cancel}>Cancel</ButtonComponent>
                    <ButtonComponent className={styles.btn_save} onClick={handleFormSave}>
                      Save
                    </ButtonComponent>
                  </div>
                </Box>
              </Box>
            );
          }}
        </Formik>
        <ModalComponent show={state.showHide} onClick={handleClose} className="instModal">
          <div className={styles.modal}>{editVal ? 'Edit Board Details' : 'Add Board'}</div>
          <Formik initialValues={initialBox || state} validationSchema={validationBox} onSubmit={handleSave} enableReinitialize>
            {(formik) => {
              return (
                <Box component="div" style={{ width: '100%', height: 'auto' }}>
                  <Form className={styles.modal_Form}>
                    {/* <label htmlFor="board">Country</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="country"
                        value={editVal ? selectedBoard() : undefined}
                        className={styles.options}
                      >
                        <option>{"Country"}</option>
                        {state.optBD.map((elm, key) => {
                          return (
                            <option value={elm.qb_syllabus_id} key={key}>
                              {elm.qb_syllabus_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box> */}
                    <label htmlFor="board">Stucle Board(Code)</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="board"
                        id="board"
                        value={editVal ? state.board : undefined}
                        onChange={(e) => selectedBoard(e)}
                        className={styles.options}
                      >
                        <option>{'Board'}</option>
                        {/* <option>{state.code}</option> */}
                        {editVal ? (
                          <option>{state.board}</option>
                        ) : (
                          state.optBD.map((elm, key) => {
                            return (
                              <option value={elm.board_id} key={key}>
                                {elm.board_code}
                              </option>
                            );
                          })
                        )}
                      </Field>
                    </Box>

                    {/* <label htmlFor="board">Medium of Instruction</label>
                    <Box className={styles.main_box_2} component="div">
                      <Field
                        as="select"
                        name="medium"
                        className={styles.options}
                        value={editVal ? selectedMedium() : undefined}
                      >
                        <option>{"Medium"}</option>
                        {state.optMed.map((elm, key) => {
                          return (
                            <option value={elm.qb_language_id} key={key}>
                              {elm.qb_language_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box> */}
                    <label htmlFor="hierarchy">Hierarchy</label>
                    <Box className={styles.main_box_3} component="div">
                      <Field
                        name="hierarchy"
                        placeholder="hierarchy"
                        className={styles.B_Field}
                        id="hierarchy"
                        value={editVal ? state.hierarchy_concat : state.hierarchy_select_val}
                        // onChange={(e) => {
                        //   setState({
                        //     ...state,
                        //     hierarchy_concat: e.target.value,
                        //   });
                        // }}
                      />
                    </Box>

                    <label htmlFor="board">Board Display Name</label>
                    <Box className={styles.main_box_3} component="div">
                      <Field
                        name="board_name"
                        placeholder="Board Display Name"
                        className={styles.B_Field}
                        id="board_name"
                        value={editVal ? state.display_name : undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            display_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <label htmlFor="board">Description</label>
                    <Box className={styles.main_box_3} component="div" style={{ height: 'maxContent', minHeight: '80px' }}>
                      <Field
                        name="desc"
                        component="textarea"
                        placeholder="Description"
                        className={styles.B_Field}
                        id="desc"
                        value={editVal ? state.description : undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            description: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box className={styles.buttonss}>
                      {editVal ? (
                        <Box className={styles.deleteBtn} onClick={handleDelete}>
                          <img
                            src={require('../../../../assets/icons/DeleteBox.png')}
                            alt="deletebox"
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      ) : null}
                      <ButtonComponent className={styles.cancelBtn} onClick={handleClose} type="reset">
                        Cancel
                      </ButtonComponent>
                      <ButtonComponent
                        className={styles.saveBtn}
                        type="submit"
                        onClick={(e) => {
                          let board_id_val = document.getElementById('board').value;
                          let board_hr_val = document.getElementById('hierarchy').value;
                          let board_name_val = document.getElementById('board_name').value;
                          let desc_val = document.getElementById('desc').value;
                          formik.setFieldValue('board', board_id_val);
                          formik.setFieldValue('hierarchy', board_hr_val);
                          formik.setFieldValue('board_name', board_name_val);
                          formik.setFieldValue('desc', desc_val);
                        }}
                        // disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Save
                      </ButtonComponent>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </ModalComponent>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </Box>
    </Box>
  );
};

export default Institution;
