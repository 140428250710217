// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Images_selectMain__AhiGd {
  border: 1px solid #eae3e3;

  border-radius: 10px;
  height: 34px;
  width: 150px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 15px;
  outline: none;
  overflow: hidden;
}
.Images_upload__52yAI {
  height: inherit;
  width: inherit;
  text-align: center;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  /* background: #fcf9f9 !important; */
}
thead {
  box-shadow: 0px 0px 1px 0.5px #dddddd;
}

table {
  border: 1px solid #dddddd;
  width: 100%;
  background: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/screens/MasterData/Tabs/Images/Images.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;;EAEzB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;AACA;;EAEE,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,oCAAoC;AACtC;AACA;EACE,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".selectMain {\n  border: 1px solid #eae3e3;\n\n  border-radius: 10px;\n  height: 34px;\n  width: 150px;\n  padding-left: 10px;\n  padding-right: 10px;\n  margin-right: 15px;\n  outline: none;\n  overflow: hidden;\n}\n.upload {\n  height: inherit;\n  width: inherit;\n  text-align: center;\n}\ntd,\nth {\n  border: 1px solid #dddddd;\n  text-align: left;\n  padding: 8px;\n  /* background: #fcf9f9 !important; */\n}\nthead {\n  box-shadow: 0px 0px 1px 0.5px #dddddd;\n}\n\ntable {\n  border: 1px solid #dddddd;\n  width: 100%;\n  background: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectMain": `Images_selectMain__AhiGd`,
	"upload": `Images_upload__52yAI`
};
export default ___CSS_LOADER_EXPORT___;
