import https from './init/https';

// PORT-GSC-1: Excel export of Group Study Circles
export const getGroupStudyCircles = async () => {
  const response = await https.get('/group_study_circles/excel-export', { responseType: 'arraybuffer' });
  return response;
};

// PORT-GSC-2: Excel Import of Group Study Circles
export const postGroupStudyCircles = async (data) => {
  return await https.post(`/group_study_circles/excel-import`, data);
};

// PORT-GSC-3: Display Group Study Circle List
export const getGroupStudyCircleList = async () => {
  const response = await https.get('/group_study_circles/search?search_text=');
  return response;
};

// PORT-GSC-4: Display Basic Details of Group Study Circle
export const getGroupStudyCircleDetails = async (group_study_circle_id) => {
  const response = await https.get(`/group_study_circles/${group_study_circle_id}/basic_info`);
  return response;
};
// PORT-GSC-5: Excel Export of Study Circle List for a Given Group Study Circle
export const getGroupStudyCircleListForExcelExport = async (grpStdid, prHierarchy, shName) => {
  const response = await https.get(
    `/group_study_circles/${grpStdid}/study_circles/excel-export?parent_hierarchy_path=${prHierarchy}&short_name=${shName}`,
    { responseType: 'arraybuffer' },
  );
  return response;
};

// PORT-GSC-6: Excel Import of Study Circles into Group Study Circle
export const postGroupStudyCircleStudyCircles = async (group_study_circle_id, data) => {
  return await https.post(`/group_study_circles/${group_study_circle_id}/study_circles/excel-import`, data);
};

// PORT-GSC-7: Display the Study Circles under a Group Study Circle
export const getGroupStudyCircleStudyCircles = async (group_study_circle_id) => {
  return await https.get(`/group_study_circles/${group_study_circle_id}/study_circles/`);
};

// PORT-GSC-8: Add Subject to Group Study Circle
export const postGroupStudyCircleSubject = async (group_study_circle_id, data) => {
  return await https.post(`/group_study_circles/${group_study_circle_id}/subjects`, data);
};

// PORT-GSC-9: Display Subjects for a Group Study Circle
export const getGroupStudyCircleSubjects = async (study_circle_ID, grpInstId, parentHierarchy, shortName) => {
  const response = await https.get(
    `/group_study_circles/${study_circle_ID}/subjects?group_institution_id=${grpInstId}&parent_hierarchy_path=${parentHierarchy}&short_name=${shortName}`,
  );
  return response;
};

// PORT-GSC-10: Delete Subject from  group study circle
export const deleteGroupStudyCircleSubject = async (group_study_circle_id, group_institution_id, group_study_circle_subject_id) => {
  return await https.delete(
    `/group_study_circles/${group_study_circle_id}/group_institutions/${group_institution_id}/subjects/${group_study_circle_subject_id}`,
  );
};

// PORT-GSC-11: Add Group Educator to Group Study Circle Subject
export const uploadGroupStudyCircleSubjectGroupEducator = async (group_study_circle_id, subject_id, data) => {
  return await https.post(`/group_study_circles/${group_study_circle_id}/subjects/${subject_id}/group_educators`, data);
};

// PORT-GSC-12: Delete Group Educator from Group Study Circle Subject
export const deleteGroupStudyCircleSubjectGroupEducator = async (
  group_study_circle_id,
  group_institution_id,
  subject_id,
  group_study_circle_subject_educator_id,
) => {
  return await https.delete(
    `/group_study_circles/${group_study_circle_id}/group_institutions/${group_institution_id}/subjects/${subject_id}/educators/${group_study_circle_subject_educator_id}`,
  );
};

// PORT-GSC-13: Retrieve list of Group educators available for a Group study circle subject
export const getGroupStudyCircleSubjectGroupEducators = async (group_study_circle_id, groupInstId, subject_id) => {
  return await https.get(
    `/group_study_circles/${group_study_circle_id}/group_institutions/${groupInstId}/subjects/${subject_id}/educators`,
  );
};
